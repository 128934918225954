import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { SignatoryesService } from 'src/app/services/service.index';
import { first, map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-signatoryesdet',
  templateUrl: './signatoryesdet.component.html',
  styleUrls: ['./signatoryes.component.css']
})
export class SignatoryesDetComponent implements OnInit {
  showmodalds: boolean;
  finalizar: boolean;
  fir = 0;
  ffiinn = 0;
  userinfo: any;
  rolinfo: any;
  constructor(
    private _router: ActivatedRoute,
    public route: Router,
    public singn: SignatoryesService,
    public http: HttpClient) { }

credit: any[] = [];
cols: any[];
selectedFac: any[];
router: Router;
idcredito: string;
tieneacceso = false;
notas: string;
ngOnInit() {
swal2.fire('')
swal2.showLoading()
this.finalizar = true;
this.idcredito = this._router.snapshot.paramMap.get('id');
this.singn.getSignatoryesXidcuatcredit(this.idcredito).subscribe( async resp => { this.credit = resp.signatories;
let fir = resp.signatories.length;
for await (let i of resp.signatories) {
  if (i.status === 'AP') {
    this.ffiinn = this.ffiinn + 1;
  }
  this.userinfo = (await this.singn.getusuario(i.user_id));
  this.rolinfo = (await this.singn.getRol(this.userinfo.data.attributes.role_id));
  i.nombre_firmante = this.userinfo.data.attributes.name;
  i.correo = this.userinfo.data.attributes.email;
  i.nombre_rol = this.rolinfo.data.attributes.name;
}
if (fir === this.ffiinn) {
  this.finalizar = false
}
swal2.close();
},
err => {
  console.log(err)
  swal2.fire({
    title: 'Error', 
    text: 'No se encontraron firmas para el credito', 
    icon: 'info',
    showConfirmButton: true,
    allowOutsideClick: false
  }).then(res => {
    if(res.value){
       this.route.navigate(['/panelcontrol/aprobarCreditos']);
    }
  });
  // console.log(err); 
} );

this.cols = [
{ field: 'correo', header: 'Correo' },
{ field: 'nombre_firmante', header: 'Firmante' },
{ field: 'nombre_rol', header: 'Rol' },
{ field: 'notes', header: 'Notas' },
{ field: 'status', header: 'Status' },
{ field: 'herramientas', header: 'Herramientas' }

];

}
showNotes(notas: string) {
  this.notas = notas;
  this.showmodalds = true;
}
hideds() {
  this.showmodalds = false;
}
solicitarFirma(obj) {
  swal2.fire('')
swal2.showLoading()
  this.singn.patchSignatory(obj).subscribe(r => {
    swal2.fire({
      title: 'Correcto', 
      text: 'La firma fue solicitada con exito', 
      icon: 'info',
      showConfirmButton: true,
      allowOutsideClick: false
    }).then(res => {
      if(res.value){
         this.ngOnInit();
      }
    });
   // window.location.reload()
  },(err) => {
    console.log(err);
    swal2.fire('Ocurrio un error','','info');
  })
}

solicitarFirmaApro(obj) {
  swal2.fire({
    title: 'Desea solicitar la firma para el credito nuevamente?', 
    text: '', 
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }).then(res => {
    if(res.value){
      this.singn.patchSignatory(obj).subscribe(r => {
        swal2.fire({
          title: 'Correcto', 
          text: 'La firma fue solicitada con exito', 
          icon: 'info',
          showConfirmButton: true,
          allowOutsideClick: false
        }).then(res => {
          if(res.value){
             this.ngOnInit();
          }
        });
       // window.location.reload()
      },(err) => {
        console.log(err);
        swal2.fire('Ocurrio un error','','info');
      })
    }
  });
  
}

finaliza() {
  swal2.fire({
    title: 'Desea finalizar la solicitud de firmas del credito?', 
    text: '', 
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
  }).then(res => {
    if(res.value){
      swal2.fire('')
      swal2.showLoading()
      this.singn.patchCustomerCreditPR(this.idcredito).subscribe(r => {
        swal2.fire({
          title: 'Correcto', 
          text: 'La solicitud de firmas fue terminada con exito', 
          icon: 'info',
          showConfirmButton: true,
          allowOutsideClick: false
        }).then(res => {
          if(res.value){
            this.route.navigate(['/panelcontrol/aprobarCreditos']);
          }
        });
       // window.location.reload()
      },(err) => {
        console.log(err);
        swal2.fire('Ocurrio un error','','info');
      })
    }
  });
}

}
