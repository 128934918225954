import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CreditosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import { ListasService } from '../../../services/listas/listas.service';
import { ReportesService } from '../../../services/reportes/reportes.service';
import { AwsService } from '../../../services/awsser/aws.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-mesa-control',
  templateUrl: './mesa-control.component.html',
  styleUrls: ['./mesa-control.component.scss']
})
export class MesaControlComponent implements OnInit {

  
  uploadForm: FormGroup;
  url: any;
  file: any;
  calificacionBC:any;
  facturas: any[] = [];
  fileName = 'Layout.xlsx';
  datosdetalles: any[] = [];
  cargado = false;
  anuality_date = [];
  selectedSol: any[] = [];
  cols: any[] = [];
  solicitudes: any[] = [];
  estatus: any[] = [];
  usuarios: any[] = [];
  idu: string;
  documentonulo: boolean;
  uploadURL: Observable<string>;
  uploadProgress: Observable<number>;
  showModal: boolean;
  showmodalds: boolean;
  idsolicitud: string;
  foliosolicitud: string;
  carpeta: string;
  nombrearchivosubido: string;
  estatus1: string;
  estatusburo: string;
  estatusindice: string;
  estatuspayment: string;
  estatusflow: string;
  car_creditnum = 0;
  mortagage_loannum = 0;

  filtro = 'SO';
  resumen_creditos = [];
  creditos_buro = [];
  calificaciones = [];
  requests = [];
  terms = [];
  paymentPeriods = [];
  intereses = 16;
  colDetallesCustomer = [];
  idRechazo = '';
  ngSelect = '';

  creditos = [];
  rates = [];
  ivaRates = [];
  payments = [];
  documents = [];
  regimen_fiscal_contribuyente = [];
  client_status = [];
  datosEmpleado = {};
  credit_status = [];
  credit_status1: any;
  buroStatus = false;
  analysisStatus = false;
  ingresos_netos: any;
  ingresos_netosnum = 0;
  egresos_netos: any;
  egresos_netosnum = 0;
  gastos_familiares: any;
  gastos_familiaresnum = 0;
  renta_casa: any;
  renta_casanum = 0;
  creditos_CPnum = 0;
  creditos_LPnum = 0;
  creditos_CP: any;
  creditos_LP: any;
  periodo_pago: any;
  analysis: any;
  accured_liabilities: any;
  debt_horizon: any;
  anual_rate: any;
  balance_due: any;
  car_credit: any;
  cash_flow: any;
  created_at: any;
  analisis_credit_status: any;
  credit_type: any;
  customer_credit_id: any;
  customer_number: any;
  debt: any;
  debtnum = 0;
  debt_rate: any;
  departamental_credit: any;
  discounts: any;
  id: any;
  last_key: any;
  lowest_key = '';
  mop_key: any;
  mortagage_loan: any;
  net_flow: any;
  net_flownum = 0;
  other_credits: any;
  overall_rate: any;
  payment_capacity: any;
  previus_credit: any;
  report_date: any;
  total_amount: any;
  total_cost: any;
  total_debt: any;
  buro: any;
  fechaReporte: any;
  pdf: any;
  urlburo: string;
  rfcBC: any;
  saldosVencidos: any;
  creditoSeleccionadoTabla = {};
  maritalStatus: any;
  lists = [];
  regimen_fiscal = [];
  
  constructor(
    private creditosService: CreditosService,
    private formBuilder: FormBuilder, private httpClient: HttpClient, 
    private listasService: ListasService,
    private reportesService: ReportesService,
    private awsService: AwsService,
    private sanitizer: DomSanitizer
    ) {}
    
    ngOnInit() {
      swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    this.creditos = [];

    this.reportesService.financialWorkers().subscribe(resp => {
      this.usuarios = [];
      Object.keys(resp).forEach(key => {
        this.usuarios.push(resp[key]);
      });
    })

    this.creditosService.ext_rates().subscribe(resp => {
      resp['data'].forEach(rate => {
        if(rate.attributes.rate_type === 'TA'){
          this.rates.push(rate.attributes);
        }else if(rate.attributes.rate_type === 'IVA'){
          this.ivaRates.push(rate.attributes);
        }
      });
    })

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    const estatus: any = document.getElementById('estatus');

    this.selectedSol = [];

    this.idu = localStorage.getItem('id'); 

    this.listasService.getLists().subscribe(resp => {
      this.estatus = [];
      resp['data'].forEach(list => {
        if(list['attributes'].domain === 'FLOW_STATUS'){
          this.estatus.push(list['attributes']);
        }
      });
    })

    this.creditosService.getCondiciones()
      .subscribe(resp => {
        this.terms = resp;
        this.creditosService.getperiodosPagos()
          .subscribe(resp => {
            this.paymentPeriods = resp;
            this.paymentPeriods.push({id:'0', key:'Pago Fijo'});
          })
        })

    this.cols = [

      { field: 'credit_folio', header: 'Folio', moneda: false },
      { field: 'credit_number', header: 'Número de Crédito', moneda: false },
      { field: 'cat2', header: 'CAT', moneda: false },
      { field: 'compañia', header: 'Compañía', moneda: false },
      { field: 'created_at', header: 'Fecha solicitud', moneda: false },
      { field: 'fecha_inicio', header: 'Fecha inicio', moneda: false },
      { field: 'empleado', header: 'Nombre Empleado', moneda: false },
      { field: 'adeudo_total', header: 'Adeudo Total', moneda: true },
      { field: 'pedido_total', header: 'Total Solicitado', moneda: true },
      { field: 'rate', header: 'Tasa', moneda: false },
      { field: 'comision', header: 'Comision', moneda: true },
      { field: 'seguro', header: 'Seguro', moneda: true },
      { field: 'destino', header: 'Destino', moneda: false },
      { field: 'plazo_value', header: 'Plazo', moneda: false },
      { field: 'pago_fijo', header: 'Pago Fijo', moneda: true },
      { field: 'estatus', header: 'Estado', moneda: false },
      { field: 'usuario', header: 'Usuario asignado', moneda: false },
    ];

    this.reportesService.userRequest().subscribe(requests => {
      this.creditos = [];
      Object.keys(requests).forEach(key => {
        let credit = requests[key];
        // credit['credit_number'] = '';
        this.reportesService.getRegistrationInfo(credit.credit_uuid).subscribe(info => {
          let datos = info[0];
          credit['destino'] = datos.extra3 ? datos.extra3 : 'Inversion';
        })
        this.creditosService.getCredit(credit.credit_uuid).subscribe(customer_credit => {
          this.creditosService.traer_customer(customer_credit['data'].attributes.id).subscribe(customer => {
            let cus = customer[0];
            credit.created_at = customer_credit['data'].attributes.created_at;
            credit['comision'] = customer_credit['data'].attributes.commission1;
            credit['seguro'] = customer_credit['data'].attributes.insurance1;
            credit.customer = cus;
            // credit['destino'] = credit.customer.destination ? credit.customer.destination : 'Inversioneeeeee';
            credit['attached'] = customer_credit['data'].attributes.attached;
            credit['credit_number'] = customer_credit['data'].attributes.credit_number;
            credit['cat'] = customer_credit['data'].attributes.destination;
            credit['cat2'] = customer_credit['data'].attributes.destination;
            credit['plazo_value'] = this.terms.find(t => t.id == customer_credit['data'].attributes.term_id).value;
            let term_type = this.terms.find(t => t.id ==  customer_credit['data'].attributes.term_id).term_type;
            credit['plazo_value'] = credit['plazo_value'] + ' ' + term_type;
            if(customer_credit['data'].attributes.user_id != null){
              let user = this.usuarios.find(u => u.id == customer_credit['data'].attributes.user_id);
              if(user != undefined){
               credit.usuario = user.name;
              }else{
                credit.usuario = 'Sin Asignar';
              }
              this.creditos.push(credit);
              this.cargar(Object.keys(requests).length);
            }else{
              credit.usuario = 'Sin Asignar';
              this.creditos.push(credit);
              this.cargar(Object.keys(requests).length);
            }
            // this.creditos.push(credit);
          })
        })
      })

      this.listasService.getLists().subscribe(lists => {
        lists['data'].forEach(list => {
          this.lists.push(list.attributes);
        });
      this.maritalStatus = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
      this.regimen_fiscal = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
      })
      // this.cargado = true;
      swal2.close();
    })
  }

  cargar(length){
    if(length == this.creditos.length){
      this.cargado = true
    }
  }

  async detalleCliente(rowData) {
    this.showmodalds = true;
    this.datosEmpleado = {};
    this.egresos_netos = rowData.customer.egresos_netos;
    if (!rowData.customer.gastos_familiares){
      this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat('2500').toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.gastos_familiaresnum = parseFloat('2500');
    } else if(rowData.customer.gastos_familiares == 0 ) {
      this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat(rowData.customer.gastos_familiares).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})) + 2500;
      this.gastos_familiaresnum = parseFloat(rowData.customer.gastos_familiares) + 2500;
  } else{
    this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat(rowData.customer.gastos_familiares).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
    this.gastos_familiaresnum = parseFloat(rowData.customer.gastos_familiares)
  }
  
  this.renta_casa = this.formatNumber(this.esNulo(parseFloat(rowData.customer.renta).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
  this.creditos_CP = this.formatNumber(this.esNulo(parseFloat(rowData.customer.creditos_cp).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
  
  this.renta_casanum = parseFloat(rowData.customer.renta)
  this.creditos_CPnum = parseFloat(rowData.customer.creditos_cp)
  this.creditos_LPnum = parseFloat(rowData.customer.creditos_lp)
    this.periodo_pago = this.formatNumber(this.esNulo(parseFloat(rowData.customer.periodo_pago).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
    // this.datosdetalles = [];
    // this.datosdetalles.push(rowData.customer);
    
    
    this.creditosService.tiene_analisis(rowData.customer).subscribe(analysis => {
      this.analysisStatus = analysis.status;
      if (this.analysisStatus){
        this.analysis = analysis.credit_analysis;
        // console.log("analysis")
        // console.log(this.analysis)
        this.accured_liabilities = this.analysis[0]['accured_liabilities']
        this.debt_horizon = this.analysis[0]['debt_horizon']
        this.anual_rate = this.analysis[0]['anual_rate'];
        this.balance_due = this.analysis[0]['balance_due'];
        this.car_credit = this.analysis[0]['car_credit'];
        this.car_creditnum = parseFloat(this.analysis[0]['car_credit']);
        this.credit_status = this.analysis[0]['credit_status'];
        this.credit_status1 = this.analysis[0]['credit_status'];
        this.cash_flow = this.analysis[0]['cash_flow'];
        if(parseFloat(this.cash_flow) >= 80 && parseFloat(this.cash_flow) <= 100 ){
          this.estatusflow = "check"
        }else if(parseFloat(this.cash_flow) >= 101 && parseFloat(this.cash_flow) <= 110){
          this.estatusflow = "priority_high"
        }else{
          this.estatusflow = "close"
        }
        this.created_at = this.analysis[0]['created_at'];
        this.analisis_credit_status = this.analysis[0]['credit_status'];
        this.credit_type = this.analysis[0]['credit_type'];
        this.customer_credit_id = this.analysis[0]['customer_credit_id'];
        this.customer_number = this.analysis[0]['customer_number'];
        this.debt = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.debtnum = parseFloat(this.analysis[0]['debt'])
        this.debt_rate = this.analysis[0]['debt_rate'];
        if(parseFloat(this.debt_rate) <= 49){
          this.estatusindice = "check"
        }else if(parseFloat(this.debt_rate) >= 50 && parseFloat(this.debt_rate) <= 65){
          this.estatusindice = "priority_high"
        }else{
          this.estatusindice = "close"
        }
        this.departamental_credit = this.analysis[0]['departamental_credit'];
        // this.discounts = this.analysis[0]['discounts'];
        // var discountsn =  parseInt(this.analysis[0]['discounts']).toFixed(2);
        this.discounts =  (parseInt(this.analysis[0]['discounts']).toFixed(2)).toString() + "%";
        // this.discounts =  discountsn.toString() + "%";
        this.id = this.analysis[0]['id'];
        this.last_key = this.analysis[0]['last_key'];
        this.lowest_key = this.analysis[0]['lowest_key'];
        this.mop_key = this.analysis[0]['mop_key'];
        this.mortagage_loan = this.analysis[0]['mortagage_loan'];
        this.mortagage_loannum = parseFloat(this.analysis[0]['mortagage_loan'])
        this.net_flow = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['net_flow']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.net_flownum = parseFloat(this.analysis[0]['net_flow'])
        this.other_credits = this.analysis[0]['other_credits'];
        this.overall_rate = this.analysis[0]['overall_rate'];
        this.payment_capacity = this.analysis[0]['payment_capacity'];
        if(parseFloat(this.payment_capacity) <= 50){
          this.estatuspayment = "check"
        }else if(parseFloat(this.payment_capacity) >= 51 && parseFloat(this.payment_capacity) >= 65){
          this.estatuspayment = "priority_high"
        }else{
          this.estatuspayment = "close"

        }
        this.previus_credit = this.analysis[0]['previus_credit'];
        this.report_date = this.analysis[0]['report_date'];
        this.total_amount = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['total_amount']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.total_cost = this.analysis[0]['total_cost'];
        this.total_debt = this.analysis[0]['total_debt'];
        this.egresos_netos = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['monthly_expenses']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.egresos_netosnum = parseFloat(this.analysis[0]['monthly_expenses'])
        this.creditos_LPnum = this.creditos_LPnum + this.mortagage_loannum + this.car_creditnum
        if(parseFloat(this.discounts) > 30){
          this.estatus1 = "close"
        }else{
          this.estatus1 = "check"
        }
        this.creditos_LP = this.formatNumber(this.esNulo(this.creditos_LPnum.toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      }else {
        console.log("NO TIENE ANALISIS")
      }

    })

    this.creditosService.tiene_buro(rowData.customer).subscribe(buro => {
      this.buroStatus = buro.status;
      if (this.buroStatus){
        this.buro = buro.credit_bureau;
        // console.log("BURO")
        // console.log(this.buro)
        if(!this.buro['bureau_info']){
          this.fechaReporte = this.buro['bureau_report']['respuesta']['persona']['hawkAlertConsulta'][0]['fechaReporte'];
          this.rfcBC = this.buro['bureau_report']['respuesta']['persona']['nombre']['rfc'];
          this.saldosVencidos = this.buro['bureau_report']['respuesta']['persona']['resumenReporte'][0]['totalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['respuesta']['persona']['scoreBuroCredito'][0]['valorScore']
          this.resumen_creditos = this.buro['bureau_report']['respuesta']['persona']['cuentas']
        }else{
          this.fechaReporte = this.buro['bureau_report']['results'][0]['updatedAt'];
          this.rfcBC = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Nombre']['RFC'];
          this.saldosVencidos = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ResumenReporte']['ResumenReporte'][0]['TotalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ScoreBuroCredito']['ScoreBC'][0]['ValorScore']
          this.resumen_creditos = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta']
        }

        this.pdf = this.buro['extra1']
        this.urlburo = `https://docs.google.com/viewer?url=${this.pdf}&embedded=true`

        if(this.calificacionBC <= 550){
          this.estatusburo = 'close'
          // this.estatusburo = 'priority_high'
        }else if(this.calificacionBC >= 551 && this.calificacionBC <=650){
          this.estatusburo = 'priority_high'
        }else{
          this.estatusburo = 'check'
        }
        this.creditos_buro = this.get_ur_credits(this.resumen_creditos)
      }else {
        // console.log("NO TIENE BURO")
      }
    })
    
    this.reportesService.getRegistrationInfo(rowData.customer.id_credito).subscribe(info => {
      this.datosEmpleado = info[0];
      this.datosEmpleado['dependientes'] = parseInt(this.datosEmpleado['senior_dependents']) + parseInt(this.datosEmpleado['minior_dependents'])
      this.datosEmpleado['martial_status_value'] = this.maritalStatus.find(m => m.key == this.datosEmpleado['martial_status']).value;
      this.datosEmpleado['fiscal_regime_value'] = this.regimen_fiscal.find(r => r.key == this.datosEmpleado['fiscal_regime']).value;
      this.datosEmpleado['business_name'] = rowData.customer.business_name;
      if(this.datosEmpleado['salary_period'] == 'Quincenal'){
        this.datosEmpleado['month_salary'] = ( parseFloat(this.datosEmpleado['salary']) + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers']))) * 2;
      }else if(this.datosEmpleado['salary_period'] == 'Semanal'){
        this.datosEmpleado['month_salary'] = (parseFloat(this.datosEmpleado['salary'])  + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers']))) * (4.33);
      }
      let ingresos_mensuales_y_otros_ingresos = parseFloat(this.datosEmpleado['month_salary']) + parseFloat(this.datosEmpleado['other_income']);
      this.ingresos_netos = this.formatNumber(this.esNulo(ingresos_mensuales_y_otros_ingresos.toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.ingresos_netosnum = ingresos_mensuales_y_otros_ingresos;
      // this.ingresos_netos = ( parseInt(this.esNulo(this.datosEmpleado['month_salary'])) + parseInt(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseInt(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseInt(this.esNulo(this.datosEmpleado['food_vouchers'])) );
      this.awsService.getUserDocuments(rowData.customer.id_contribuyente).subscribe(docs => {
        this.documents = [];
        docs['data'].forEach(doc => {
          if(doc.attributes.name == "Comprobantes de ingresos del empleado"){
            var arrayUrl = doc.attributes.url.split('*');
            arrayUrl.forEach(url => {
              if(url != ''){
                let params = {
                  contributor_id: doc.attributes.contributor_id,
                  created_at: doc.attributes.created_at,
                  extra1: null,
                  extra2: null,
                  extra3: null,
                  file_type_document_id: doc.attributes.file_type_document_id,
                  id: doc.attributes.id,
                  name: doc.attributes.name,
                  notes: null,
                  status: doc.attributes.status,
                  updated_at: doc.attributes.updated_at,
                  url: url
                }
                let variable = doc.attributes;
                variable.url = url;
                this.documents.push(params);
              }
            });
          }else{
            this.documents.push(doc.attributes);
          }
        });
        if(this.buroStatus){
          if(this.buro['extra1'] != null){
            this.documents.push({'url': this.buro['extra1'], 'name': 'Reporte Buró de Crédito'});
          }
        }
        if(this.datosEmpleado['extra1'] !== null && this.datosEmpleado['extra1'] !== undefined){console.log(this.datosEmpleado['extra1'])
          this.documents.push({'url': this.datosEmpleado['extra1'], 'name': 'Expediente'});
        }else{
        }     
      })
    });

  }

  hideds() {
    this.showmodalds = false;
  }

  esNulo (dato){
    if(dato == null){
      return 0;
    }else{
      return dato;
    }
  }

  showModalf(rowData) {
    var id = rowData.credit_uuid;
    this.creditosService.getCredit(id).subscribe(resp => {
      this.creditoSeleccionadoTabla = resp['data'].attributes;
      this.payments = [];
      this.creditosService.customerCreditPayments(id).subscribe(pays => {
        pays['data'].forEach(p => {
  /*         this.payments.push(p['attributes']); */
          const payment = p['attributes'];/* borrar */
          if(payment.commission == null ){
            payment.commission = 0
          }
          if(payment.insurance == null ){
            payment.insurance = 0
          }
  /*         payment.insurance = 100; // Valor fijo de seguro
          payment.commission = 50; // Valor fijo de comisión */
          this.payments.push(payment);/* borrar */
        });
      });
      
      this.showModal = true; // Show-Hide Modal Check
    })
  }

  hideModal() {
    this.showModal = false;
  }  

  
  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  guardar(){
    let usuario = (document.getElementById('usuario') as HTMLInputElement).value;
    let estatus = (document.getElementById('estatus') as HTMLInputElement).value;
    if(usuario != '0' || estatus != '0'){
      if(this.selectedSol.length > 0){
        let update = {};
        this.selectedSol.forEach(credito => {
          update['customer_credit'] = credito;
          if(usuario != '0'){
            update['customer_credit']['user_id'] = usuario;
          }
          if(estatus != '0'){
            update['customer_credit']['status'] = estatus;
          }
          this.updateCredit(credito.credit_uuid, update);
        })
      }else{
        swal2.fire('Alerta', 'Debe seleccionar al menos un crédito', 'info');
      }
    }else{
      swal2.fire('Alerta', 'Debe seleccionar un usuario o estatus a asignar', 'info');
    }
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  updateCredit(uuid, update){
    this.creditosService.updateCredit(uuid, update).subscribe(resp => {
      swal2.fire({
        title: 'Éxito',
        text: 'Crédito actualizado correctamente',
        icon: 'success',
        showConfirmButton: true
      }).then(res => {
        if(res.value){
          this.ngOnInit();
        }
      })
    }, err => {
      console.log('error', err);
      try{
        swal2.fire('Alerta', err.error.errors[0], 'info');
      }catch{
        swal2.fire('Alerta', 'Ha ocurrido un error', 'info');
      }
    })
  }

  keyFolio(e: KeyboardEvent, rowData){
    if(e.keyCode == 13){
      console.log('pulsaste enter', rowData);
      let update = {};
      update['customer_credit'] = {};
      update['customer_credit']['credit_number'] = rowData.credit_number;
      rowData['cat'] = rowData['cat2'];
      update['customer_credit']['destination'] = rowData.cat;
      swal2.showLoading();
      this.updateCredit(rowData.credit_uuid, update);
    }
  }

  get_ur_credits(credits){ 
    var ur_credits = {mop: 'UR', cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0,
                saldo_vencido: 0, pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0,
                saldo_cerradas: 0, monto_cerradas: 0}

    var credits_01 = {mop: '01',cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0, saldo_vencido: 0,
    pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0, saldo_cerradas: 0,
    monto_cerradas: 0}


    var all_credits = []


    credits.forEach(credit =>{
      if(credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === 'UR'){
        ur_credits['cuentas_cerradas'] += 1
        ur_credits['limite_cerradas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        ur_credits['maximo_cerradas'] += parseInt(credit['CreditoMaximo'])
        ur_credits['saldo_cerradas'] += parseInt(credit['SaldoActual'])
        ur_credits['monto_cerradas'] += parseInt(credit['MontoPagar'])
      }else if (credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === '01'){
        credits_01['cuentas_cerradas'] += 1
        credits_01['limite_cerradas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        credits_01['maximo_cerradas'] += parseInt(credit['CreditoMaximo'])
        credits_01['saldo_cerradas'] += parseInt(credit['SaldoActual'])
        credits_01['monto_cerradas'] += parseInt(credit['MontoPagar'])
      }else if (!credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === 'UR'){
        ur_credits['cuentas_abiertas'] += 1
        ur_credits['limite_abiertas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        ur_credits['maximo_abiertas'] += parseInt(credit['CreditoMaximo'])
        ur_credits['saldo_actual'] += parseInt(credit['SaldoActual'])
        ur_credits['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        ur_credits['pago_realizar'] += parseInt(credit['MontoPagar'])
      }else if (!credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === '01'){
        credits_01['cuentas_abiertas'] += 1
        credits_01['limite_abiertas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        credits_01['maximo_abiertas'] += parseInt(credit['CreditoMaximo'])
        credits_01['saldo_actual'] += parseInt(credit['SaldoActual'])
        credits_01['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        credits_01['pago_realizar'] += parseInt(credit['MontoPagar'])
      }
    });

    all_credits.push(ur_credits)
    all_credits.push(credits_01)

    return all_credits
  }

  exportexcel() {
    this.fileName = "Layout" + '.xlsx';
    const Excel = require('exceljs');
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('base');
    let titulo = ['PLANTILLA DE PAGOS PROGRAMADOS']
    worksheet.addRow(titulo);
    worksheet.mergeCells(1, 1, 1, 2);
    let pagos = ['No. De pagos', this.payments.length]
    worksheet.addRow(pagos);
    let header=['Fecha de pago', 'Capital'];
    worksheet.addRow(header);
    for (const prop in this.payments) {
      var datePipe = new DatePipe('en-US');
      var fecha = datePipe.transform(this.payments[prop].payment_date, 'dd/MM/yyyy');
      worksheet.addRow([
        // this.payments[prop].pay_number,
        // new Date(this.payments[prop].payment_date).toLocaleDateString('en-GB'),
        fecha,
        this.payments[prop].capital
      ]);
    } 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
  });
  }


}
