import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-maininversionista',
  templateUrl: './maininversionista.component.html',
  styleUrls: ['./maininversionista.component.css']
})
export class MaininversionistaComponent implements OnInit {
  focus: any;
  focus1: any;
  rangeDates: Date[];
  cols: any[];
  listas: any[] = [];
  data: any;
  constructor() { }

  ngOnInit(): void {
    this.listas = [{
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '03/03/2021',
      fcredito: '28/03/2021'
    },
    {
    orden: '225',
    estatus: 'Pendiente',
    solicitante: 'Benjamin Flores',
    direccion: 'Chihuahua, Mexico',
    icredito: '03/04/2021',
    fcredito: '28/04/2021'
    },
    {
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '13/04/2021',
      fcredito: '28/04/2021'
      },
      {
        orden: '225',
        estatus: 'Pendiente',
        solicitante: 'Benjamin Flores',
        direccion: 'Chihuahua, Mexico',
        icredito: '15/04/2021',
        fcredito: '28/04/2021'
        },
    {
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '03/05/2021',
      fcredito: '28/05/2021'
      }
  ]

    this.cols = [

      { field: 'orden', header: '# Orden' },
      { field: 'estatus', header: 'Estatus' },
      { field: 'solicitante', header: 'Solicitante' },
      { field: 'direccion', header: 'Direccion' },
      { field: 'icredito', header: 'Inicio del credito' },
      { field: 'fcredito', header: 'Fin del credito' }

  ];

  this.data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            borderColor: '#4bc0c0'
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            borderColor: '#565656'
        }
    ]
}
  }

  aplicaFiltroCalendario() {
    this.listas = [{
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '03/03/2021',
      fcredito: '28/03/2021'
    },
    {
    orden: '225',
    estatus: 'Pendiente',
    solicitante: 'Benjamin Flores',
    direccion: 'Chihuahua, Mexico',
    icredito: '03/04/2021',
    fcredito: '28/04/2021'
    },
    {
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '13/04/2021',
      fcredito: '28/04/2021'
      },
      {
        orden: '225',
        estatus: 'Pendiente',
        solicitante: 'Benjamin Flores',
        direccion: 'Chihuahua, Mexico',
        icredito: '15/04/2021',
        fcredito: '28/04/2021'
        },
    {
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '03/05/2021',
      fcredito: '28/05/2021'
      }
  ]
    let fechaInicio: string;
    let fechaFin: string;
    let listasTemp = this.listas;
    let rangoFechas: any[];
    console.log(this.rangeDates);
    if ( this.rangeDates === undefined ) {
      console.log('seleccione una o un rango de fechas');
    } else if ( this.rangeDates[1] === null ) {
      fechaInicio = this.getFormattedDate( this.rangeDates[0] );
      this.listas = [];
      for ( const prop in listasTemp ) {
        if (listasTemp[prop].icredito === fechaInicio) {
          this.listas.push( listasTemp[prop] );
        }
      }
      console.log(this.listas);
    } else {
      this.listas = [];
      fechaInicio = this.getFormattedDate( this.rangeDates[0] );
      fechaFin = this.getFormattedDate( this.rangeDates[1] );
      var desde = moment(fechaInicio);
      var hasta = moment(fechaFin);
      rangoFechas = this.diasEntreFechas( desde, hasta );
      for ( const prop in listasTemp ) {
        for (const prep in rangoFechas) {
          if ( listasTemp[prop].icredito === rangoFechas[prep] ) {
            this.listas.push( listasTemp[prop] );
          }
        }
      }
    }
  }

  getFormattedDate( fecha ) {
    const a = fecha;
    a.setMinutes( a.getMinutes() + a.getTimezoneOffset() );
    let montha = '' + (a.getMonth() + 1);
    let daya = '' + a.getDate();
    const yeara = a.getFullYear();
    if (montha.length < 2) {
      montha = '0' + montha;
    }
    if (daya.length < 2) {
      daya = '0' + daya;
    }
    return( [yeara, montha, daya].join('-'));
  }

   toMs(dateStr) {
    // desarmamos el string por los '-' los descartamos y lo transformamos en un array
    let parts = dateStr.split("-")
    // parts[2] es año
    // parts[1] el mes
    // parts[0] el día
    return new Date(parts[2], parts[1] - 1, parts[0]).getTime()
  }

    diasEntreFechas (desde, hasta) {
  	var dia_actual = desde;
    var fechas = [];
  	while (dia_actual.isSameOrBefore(hasta)) {
    	fechas.push(dia_actual.format('DD/MM/YYYY'));
   		dia_actual.add(1, 'days');
  	}
  	return fechas;
};

  limpiaFiltroTabla() {
    this.listas = [{
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '03/03/2021',
      fcredito: '28/03/2021'
    },
    {
    orden: '225',
    estatus: 'Pendiente',
    solicitante: 'Benjamin Flores',
    direccion: 'Chihuahua, Mexico',
    icredito: '03/04/2021',
    fcredito: '28/04/2021'
    },
    {
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '13/04/2021',
      fcredito: '28/04/2021'
      },
      {
        orden: '225',
        estatus: 'Pendiente',
        solicitante: 'Benjamin Flores',
        direccion: 'Chihuahua, Mexico',
        icredito: '15/04/2021',
        fcredito: '28/04/2021'
        },
    {
      orden: '225',
      estatus: 'Pendiente',
      solicitante: 'Benjamin Flores',
      direccion: 'Chihuahua, Mexico',
      icredito: '03/05/2021',
      fcredito: '28/05/2021'
      }
  ]
  }

}
