import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import { ContribuyentesService } from '../../services/registrocontribuyente/contribuyente.service';

@Component({
  selector: 'app-empleados-cadenas',
  templateUrl: './empleados-cadenas.component.html',
  styleUrls: ['./empleados-cadenas.component.css']
})
export class EmpleadosCadenasComponent implements OnInit {

  constructor(
    public contribuyentesService: ContribuyentesService,
    public router: Router,
    public http: HttpClient ) { }

  cols: any[];
  contribuyentes: any[] = [];
  _selectedColumns: any[];

  ngOnInit() {
    swal2.showLoading();
    this.contribuyentesService.getContribuyentes().subscribe( resp => {
      resp.forEach(element => {
        // this.contribuyentesService.getCustomerxcontribuyente(element.id)
        //   .subscribe(customers => {
        //     if(customers.length > 0){
        //       customers.forEach(customer => {
        //         customer.isCustomer = true;
        //         customer.isFunder = false;
        //         this.contribuyentes.push(customer);
        //       })
        //     }
        //   });
          this.contribuyentesService.getCadenaxcontribuyente(element.id)
          .subscribe(cadenas => {
            if(cadenas.length > 0){
              cadenas.forEach(cadena => {
                cadena.isCustomer = false;
                cadena.isFunder = true;
                cadena.name = cadena.business_name;
                this.contribuyentes.push(cadena);
              })
            }
          });
      });
      swal2.close();
    } );

    this.cols = [

      { field: 'contributor_id', header: 'Contributor ID' },
      { field: 'name', header: 'Nombre' },
      // { field: 'status', header: 'Status' },
      // { field: 'isCustomer', header: 'Empleado' },
      { field: 'isFunder', header: 'Cadena' }

  ];

    this._selectedColumns = this.cols;

  }

  borrar(tipo: string, contributor_id: string, id: string){
    this.contribuyentesService.eliminarEmpleadoCadena(tipo, contributor_id, id)
      .subscribe(resp => {
        this.ngOnInit();
      });
  }

}
