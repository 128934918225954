import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-simulacion-administrador',
  templateUrl: './simulacion-administrador.component.html',
  styleUrls: ['./simulacion-administrador.component.css']
})
export class SimulacionAdministradorComponent implements OnInit {

  usuarios: any[] = [];
  cols: any[];
  nombre = '';

  constructor(private usuariosservice: UsuarioService,
    public router: Router ) { }

  ngOnInit(): void {
    this.usuariosservice.getUsuariosConCustomer().subscribe(resp => {
      this.usuarios = resp;
    })
    this.cols = [

      { field: 'name', header: 'Nombre' },
      { field: 'email', header: 'Correo' },
      { field: 'job', header: 'Puesto' },
      { field: 'gender', header: 'Genero' },
      { field: 'status', header: 'Estatus' },
      // { field: 'nombrerol', header: 'Rol' },
    ];
  }

  seleccionarUsuario(user){
    this.router.navigate([`/administrador/solicitudcredito/${user.id}`]);
  }

}
