import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class TuidentidadService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

  validation_success(params) {
    let headers = new HttpHeaders({
        'Access-Control-Allow-Origin': 'http://localhost:4200/#/registro',
        'accept': 'application/json',  
        'ApiKey': 'key' ,
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Headers" : "Content-Type",
            "Access-Control-Allow-Methods": "OPTIONS,POST,GET"
        });
    let options = { headers: headers };
    console.log(options)
     // const url = 'https://dev.tuidentidad.com/api/Business/ine';
      const url = `https://dev.tuidentidad.com/api/Business/ine?checkInfo=true&checkQuality=true&checkPatterns=true&checkCurp=true&checkFace=true&debugRenapo=false`
      return this.http.post( url, null, options )
      
  }


}