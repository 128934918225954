import { Component, OnInit } from '@angular/core';
import { SinAutenticarService } from '../services/sin-autenticar.service';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false
    };

    focus;
    focus1;
    datos = false;

    title = 'appBootstrap';
  
    public isCollapsed = false;

    constructor(private sinAutenticar:SinAutenticarService,
        private usuarioservice: UsuarioService,
        public router: Router) { }

    ngOnInit(): void {
    }

    change(){
        this.datos = !this.datos; 
    }


    registrar() {
        const nombre: any = (document.getElementById('nombre') as HTMLInputElement).value;
        const correo: any = (document.getElementById('correo') as HTMLInputElement).value;
        const empresa: any = (document.getElementById('empresa') as HTMLInputElement).value 
        this.usuarioservice.send_client_registration_mailer(nombre,correo,empresa).subscribe( resp => {
            const registroStatus = resp.status;
            if (registroStatus){
                  swal2.fire(
                    'Solicitud de registro enviada',
                    'Estamos revisando tu solicitud, se te estaran enviando indicaciones al correo que proporcionaste',
                    'success'
                 ). then ( res => {
                    if ( res.value ) {
                      // console.log("respuesta")
                      // console.log(res)
                      window.location.reload();
                      // this.router.navigate(['/panelcontrol/opciones']);
                    }
                  } );
                 setTimeout(() => {
                  this.router.navigate(['/home']);
                }, 1800);
            }else {
                const registroMensaje = resp.message;
                if (registroMensaje == 'El correo no tiene formato correcto')
                {
                    swal2.fire(
                        'El correo no tiene formato correcto',
                        'Error',
                        'error'
                     );
                }else{
                swal2.fire(
                    'Favor de llenar todos los campos',
                    'Error',
                    'error'
                 );
                }
          
              }


              }
               );
      }
}
