import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'console';
import { AwsService, CreditosService, ListasService, UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-alta-empleado',
  templateUrl: './alta-empleado.component.html',
  styleUrls: ['./alta-empleado.component.css']
})
export class AltaEmpleadoComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  documentos = false;
  panelPersonales = false;
  panelContributor = false;
  panelAddress = false;
  panelCustomer = false;
  panelReferences = false;
  paneljob = false;
  countries = [];
  states = [];
  municipies = [];
  colonias = [];
  capturaCp = false;
  regimen = '';
  first_name = '';
  last_name = '';
  second_last_name = '';
  docArray = [];

  person = new FormGroup({
    fiscal_regime : new FormControl(null),
    first_name : new FormControl(null),
    last_name : new FormControl(null),
    second_last_name : new FormControl(null),
    gender : new FormControl(null),
    birthdate : new FormControl(null),
    nationality : new FormControl(null),
    birth_country : new FormControl(null),
    birthplace : new FormControl(null),
    phone : new FormControl(null),
    mobile : new FormControl(null),
    email : new FormControl(null),
    martial_status : new FormControl(null),
    martial_regime : new FormControl('NA'),
    minior_dependents : new FormControl('0'),
    senior_dependents : new FormControl('0'),
    id_type : new FormControl(null),
    identification : new FormControl(null),
    rfc : new FormControl(null),	
    curp : new FormControl(null),
    imss : new FormControl(null),
    fiel : new FormControl('NA'),
    housing_type : new FormControl(null),
    /* 
    id : new FormControl(null),
    extra1 : new FormControl(null),
    extra2 : new FormControl(null),
    extra3 : new FormControl(null),
    created_at : new FormControl(null),
    updated_at : new FormControl(null), */
  });

  contributor = new FormGroup({
    contributor_type : new FormControl(null),
    bank : new FormControl(null),
    account_number : new FormControl(null),
    clabe : new FormControl(null),
    /* extra1 : new FormControl(null),
    extra2 : new FormControl(null),
    extra3 : new FormControl(null),
    person_id : new FormControl(null),
    legal_entity_id : new FormControl(null),
    id : new FormControl(null),
    created_at : new FormControl(null),
    updated_at : new FormControl(null), */
  });

  contributor_address = new FormGroup({
    address_type : new FormControl(null),
    suburb : new FormControl(null),
    suburb_type : new FormControl(null),
    street : new FormControl(null),
    external_number : new FormControl(null),
    apartment_number : new FormControl(null),
    postal_code : new FormControl(null),
    address_reference : new FormControl(null),
    state_id : new FormControl(null),
    municipality_id : new FormControl(null),
    /* contributor_id : new FormControl(null),
    id : new FormControl(null),
    created_at : new FormControl(null),
    updated_at : new FormControl(null), */
  });

  gov_customer = new FormGroup({
    first_name : new FormControl(null),
    last_name : new FormControl(null),
    second_last_name : new FormControl(null),
    age : new FormControl(null),
    public_charge : new FormControl({value: 'SI', disabled: true}),
    public_charge_det : new FormControl(''),
    relative_charge : new FormControl(null),
    relative_charge_det : new FormControl(''),
    responsible : new FormControl(null),
    responsible_detail : new FormControl(''),
    salary : new FormControl('0'),
    salary_period : new FormControl(null),
    extra_income : new FormControl('0'),
    expenses : new FormControl(null),
    extra_expenses : new FormControl(null),
    payment_capacity : new FormControl({value: '', disabled: true}),
    customer_type : new FormControl(null),
    company_id : new FormControl(null),
    /* id : new FormControl(null),
    extra1 : new FormControl(null),
    extra2 : new FormControl(null),
    extra3 : new FormControl(null),
    created_at : new FormControl(null),
    updated_at : new FormControl(null),
    contributor_id : new FormControl(null),
    file_type_id : new FormControl(null), */
  });

  customer_personal_references = new FormGroup({
    first_name : new FormControl(null),
    last_name : new FormControl(null),
    second_last_name : new FormControl(null),
    address : new FormControl(null),
    phone : new FormControl(null),
    reference_type : new FormControl(null),
    /* id : new FormControl(null),
    created_at : new FormControl(null),
    updated_at : new FormControl(null),
    customer_id : new FormControl(null), */
  });

  gov_actual_jobs = new FormGroup({
    agency : new FormControl(null),
    seniority : new FormControl(null),
    direct_boss : new FormControl('NA'),
    title : new FormControl(null),
    area : new FormControl(null),
    department : new FormControl(null),
    office_phone : new FormControl(null),
    /* extra1 : new FormControl(null),
    extra2 : new FormControl(null),
    extra3 : new FormControl(null),
    id : new FormControl(null),
    created_at : new FormControl(null),
    updated_at : new FormControl(null),
    gov_customer_id : new FormControl(null), */
  });
  lists = [];
  generos = [];
  maritalStatus = [];
  regimenFiscal = [];
  addressType = [];
  suburbType = [];
  banks = [];
  companies = [];
  mantenimiento = false;
  pais = '';
  paisNac = '';
  govCustomerId = '';
  govCustomerObject = {};
  showModal = false;
  calculo = '';
  person_id: any;
  contributor_id: any;
  contributor_address_id: any;
  gov_customer_id: any;
  gov_actual_job_id: any;
  regimenMatrimonial = [];
  
  constructor(private aws: AwsService,
              private listasService: ListasService,
              private usuarioService: UsuarioService,
              private route: ActivatedRoute,
              public router: Router,
              private creditosService: CreditosService
  ) { }

  async ngOnInit() {
    await this.aws.getCountriesD().toPromise().then(resp => { this.countries = resp; }, error => { console.log(error); });
    await this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
      this.generos = this.lists.filter(p => p.domain === 'USER_GENDER');
      this.maritalStatus = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
      this.regimenFiscal = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
      this.addressType = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
      this.suburbType = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
      this.regimenMatrimonial = this.lists.filter(p => p.domain === 'PERSON_MARITAL_REGIME');
      this.aws.getCompanyes().subscribe(resp => {
        this.companies = resp;
      })
    })
    
    if(this.route.snapshot.paramMap.get('customer_id')){
      this.mantenimiento = true;
      this.govCustomerId = this.route.snapshot.paramMap.get('customer_id');
      this.usuarioService.getGovCustomer(this.govCustomerId).subscribe(resp => {
        this.govCustomerObject = resp['data'];
        this.person_id = resp['data'].id;
        this.contributor_id = resp['data']['relations']['contributors'][0].id;
        this.contributor_address_id = resp['data']['relations']['contributor_addresses'][0].id;
        this.gov_customer_id = resp['data']['relations']['gov_customers'][0].id;
        this.gov_actual_job_id = resp['data']['relations']['gov_actual_job'][0].id;
        resp['data']['relations']['contributors'][0]
        Object.keys(this.person.value).forEach(k => {
          this.person.get(k).setValue(resp['data'].attributes[k]);
        })
        Object.keys(this.contributor.value).forEach(k => {
          this.contributor.get(k).setValue(resp['data']['relations']['contributors'][0]['attributes'][k]);
        })
        Object.keys(this.contributor_address.value).forEach(k => {
          if(k == 'postal_code'){
            this.getColonias(resp['data']['relations']['contributor_addresses'][0]['attributes']['postal_code']);
          }
          if(k == 'state_id'){
            this.pais = this.countries.find(p => p.name == 'Mexico').id
            this.buscaEdo(this.pais);
          }
          if(k == 'municipality_id'){
            this.buscaMun(resp['data']['relations']['contributor_addresses'][0]['attributes']['state_id']);
          }
          this.contributor_address.get(k).setValue(resp['data']['relations']['contributor_addresses'][0]['attributes'][k]);
        })
        Object.keys(this.gov_customer.value).forEach(k => {
          this.gov_customer.get(k).setValue(resp['data']['relations']['gov_customers'][0]['attributes'][k]);
          resp['data']['relations']['gov_customers'][0]['attributes']['payment_capacity'] = (parseFloat(resp['data']['relations']['gov_customers'][0]['attributes']['salary']) + parseFloat(resp['data']['relations']['gov_customers'][0]['attributes']['extra_income'])) * 0.3;
          this.calculoCapacidad();
        })
        Object.keys(this.gov_actual_jobs.value).forEach(k => {
          this.gov_actual_jobs.get(k).setValue(resp['data']['relations']['gov_actual_job'][0]['attributes'][k]);
        })
        this.docArray = [];
        resp['data']['relations']['contributor_documents'].forEach(d => {
          this.docArray.push(d.attributes);
        });
      });
    }
    

  this.aws.getPersonBank().subscribe(resp => {
     resp['data'].forEach(bank => {
       this.banks.push(bank.attributes);
     });
    })

    
  }

  calculoCapacidad(){
    this.calculo = ((parseFloat(this.gov_customer.get('salary').value) + parseFloat(this.gov_customer.get('extra_income').value)) * 0.3).toString();
    let regex = /(\d*.\d{0,2})/; 
    this.calculo = this.calculo.toString().match(regex)[0];

    this.calculo = this.calculo.replace(/[^0-9.]/g, '');
    
    // mantengo solo el primer decimal
    const parts = this.calculo.split('.');
    if (parts.length > 2) {
      this.calculo = parts[0] + '.' + parts.slice(1).join('');
    }
    
    // formateo y limito a solo dos decimales
    let [integerPart, decimalPart] = this.calculo.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    decimalPart = (decimalPart || '').padEnd(2, '0').substring(0, 2);

    // actualizo visualmente
    // input.value = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
    this.calculo = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`



    this.gov_customer.get('payment_capacity').setValue(this.calculo);
    // this.gov_customer.value['payment_capacity'] = this.calculo;
  }

  // para validar y limitar el número de decimales en tiempo real
  validateInput(event: any) {
    const input = event.target as HTMLInputElement;
    var value = this.creditosService.limitarDecimales(event);
    input.value = value;
  }
  
  // Para cuando pierda el foco formatear
  fmCurrency(event: any) {
    const input = event.target as HTMLInputElement;
    var value = this.creditosService.formatearFocoPerdido(event);
    input.value = value;
  }

  dragEnter(id: string) {
    document.getElementById(id).style.border = "dashed 3px #73CB38";
  }
  dragLeave(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }

  hideds(){
    this.showModal = false;
    swal2.fire('Atención', 'Empleado creado correctamente', 'success').then(result => {
      if(result.isConfirmed){
        window.location.reload();

      }
    })
  }

  guardarModal(){
    this.showModal = false;
    swal2.fire('Atención', 'Empleado creado correctamente', 'success').then(result => {
      if(result.isConfirmed){
        window.location.reload();

      }
    })
  }

  async fileBrowseHandler( event: any, doc: string, idd ) {
    const file: File = event.target.files[0];

  if (file && file.type !== 'application/pdf') {
    swal2.fire('Atención', 'Solo se permiten archivos PDF', 'info');
    event.target.value = ''; // Limpia el input
    return;
  }
    await this.callupload(file, idd);
  }

  async callupload(file, idd) {
    try {
      const r = await this.aws.uploadFileSB(file, this.govCustomerId);
      console.log(r);
      (await this.aws.patchUrl(this.govCustomerObject['relations']['contributors'][0].id, idd, r['url'])).toPromise().then(resp => {
        var index = this.docArray.findIndex(p => p.id == idd);
        this.docArray[index].url = r['url'];
        this.docArray[index].status = 'IN';
      });
    } catch (error) {
      console.log('error', error);
      let resp = {
        status: false,
        resp: error,
        msg: 'Fallo al subir archivo'
      }
      try{
        swal2.fire(error.error.error, error.error.exception, 'info');
      }catch{
        swal2.fire('Error', 'Ocurrió algo en la carga', 'info')
      }
      return resp
    }
  }

  async eliminaDocumento(idd){
    (await this.aws.eliminaDocumento(this.govCustomerObject['relations']['contributors'][0].id, idd, '')).toPromise().then(resp => {
      var index = this.docArray.findIndex(p => p.id == idd);
      this.docArray[index].url = '';
      this.docArray[index].status = 'PI';
    });
   }

  buscaEdo(id) {
  this.aws.getStatesId(id).subscribe( r => {this.states = r;} )
  }

  buscaMun(id) {
    this.aws.getMunicipios(id).subscribe( r => {this.municipies = r;} )
  }

  getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {
      this.colonias = r;
      this.capturaCp = false;
      if(this.colonias.length == 0){
        this.capturaCp = true;
      }
    })
  }

  cerraroregusu() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }

  regUsr(){
    var params = {};
    // params['rfc'] = 'AABB999199A7';
    // params['company_rfc'] = this.company['rfc'];
    params['type'] = 'customer';
    this.person.value['fiscal_regime'] = this.regimen;
    params['person'] = this.person.value;
    params['contributor'] = this.contributor.value;
    params['contributor_address'] = this.contributor_address.value;
    this.gov_customer.value['first_name'] = this.first_name;
    this.gov_customer.value['last_name'] = this.last_name;
    this.gov_customer.value['second_last_name'] = this.second_last_name;
    this.gov_customer.value['customer_type'] = 'PFGY';
    params['gov_customer'] = this.gov_customer.value;
    params['gov_actual_job'] = this.gov_actual_jobs.value;
    console.log(params);
    
    if(!this.mantenimiento){
      params['customer_personal_references'] = this.customer_personal_references.value;
      params['gov_customer']['payment_capacity'] = this.calculo;
      this.usuarioService.crearEmpleadoYucatan(params).subscribe(resp => {
        // window.location.reload();
        console.log(resp);
        this.govCustomerObject = resp['data'];
        this.docArray = [];
        resp['data']['relations']['contributor_documents'].forEach(d => {
          this.docArray.push(d.attributes);
        });
        this.showModal = true;
      }, error => {
        console.log(error);
        try{
          if(typeof(error.error.error) == 'string'){
            swal2.fire('Atención', error.error.error, 'error');
          }else{
            var mensaje = '';
            Object.keys(error.error.error).forEach(k => {
              mensaje += k + ': ' + error.error.error[k][0] + '\n';
            })
            console.log(mensaje);
            swal2.fire({
              title: 'Atención',
              html: mensaje,
              icon: 'error'
            });
          }
        }catch{
          try{
            swal2.fire('Atención', error.error.errors[0], 'error');
          }catch{
            swal2.fire('Atención', 'Algo salió mal', 'error');
          }
        }
      })
    }else{
      params['person_id'] = this.person_id;
      params['contributor_id'] = this.contributor_id;
      params['contributor_address_id'] = this.contributor_address_id;
      params['gov_customer_id'] = this.gov_customer_id;
      params['gov_actual_job_id'] = this.gov_actual_job_id;
      this.usuarioService.actualizaEmpleadoYucatan(this.govCustomerId, params).subscribe(resp => {
        console.log(resp);
        swal2.fire('Éxito', 'Empleado actualizado correctamente', 'success').then((result) => {
          if (result.isConfirmed) {
            this.router.navigate(['/panelcontrol/empleadosYucatan']);
          }
        });
      },error => {
        console.log(error);
        try{
          swal2.fire('Atención', error.error.errors[0], 'error');
        }catch{
          swal2.fire('Atención', 'Algo salió mal', 'error');
        }
      });
    }
  }

}
