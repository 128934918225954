import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsService, UsuarioService } from 'src/app/services/service.index';

import swal2 from 'sweetalert2';

@Component({
  selector: 'app-modregempdet',
  templateUrl: './modregempdet.component.html',
  styleUrls: ['./modregemp.component.css']
})
export class ModregempdetComponent implements OnInit {
  files = [];
  filesNames = [];
  gender: any[];
  companyes: any[];
  bank: any[];
  colonias: any[];
  states: any[] = [];
  mun: any[] = [];
  usuario: any[] = [];
  countries = [];
  contributordocuments: any[] = [];
  contributorNominaId: '';
  contributorNominaDocId: '';
  contributorNominaDocs: any[] = [];
  nombre = '';
  idu = '';
  fileIneUrl: string;
  myForm = new FormGroup({
   filesIngresos: new FormControl(),
   fileIne: new FormControl(),
   fileCompDom: new FormControl(),
   fileCurp: new FormControl(),
   fileRfc: new FormControl(),
   fileXml: new FormControl(),
   fileEcuenta: new FormControl(),
   fileVrfc: new FormControl(),
   fileVine: new FormControl(),
   fileCsf: new FormControl(),
   fileLnegras: new FormControl(),
   fileAsiac: new FormControl()
 });
  keycompextra3: any[] = [];
  casados: string[] = ['SI', 'NO'];
   escasado: any[] = [{ val: 'CA', mos: 'SI' },{val: 'SO', mos: 'NO'}];
   regimen: any[] = [{val: 'BM', mos: 'Bienes mancomunados'}, {val: 'BS', mos: 'Bienes separados'}];
   regUsuario =  {
      datosEspeciales: {
        person_id: '', contributor_id: '', customer_id: '', contributor_addres_id: '', customer_pr_id: ''
      },
      datosPersonales: {
         nombre: '',sexo: '',fechanacimiento: '',edad: '',pais: '',paisres: '',nacionalidad: '',RFC: '',CURP: '',
         identificacion: '',nidentificacion: '',telefono: '',casado: 'SO',regimen: '',dependientes: 'NO',menores:'0',mayores:'0',
         imms: '', apellidom: '', apellidop: ''
      },
      datosDomicilio: {
         calle: '',nexterior: '',ninterior: '',colonia: '',cp: '',delymun: '',ciudad: '',paisdom: '',tvivienda: 'Rentada',
         telparticular: '',telmovil: '',mailface: '', referencia: '', house_rent: '0.00'
      },
      datosEmpleoAct: {
         empresadat: '',puestoactualemp: '',actividademp: '',calleemp: '',coloniaemp: '',cpemp: '',delymunemp: '',ciudademp: '',
         estadoemp: '',telefonoemp: '', companyid: '', rfcemp: '', arrdata: [], extra3: null
      },
      refPers: [
         {nombrenf1: '', apellidopnf1:'', apellidomnf1: '',domnf1: '',telnf1: '', idcontrolnf1: '', nombrenf1control: ''},
         {nombrenf2: '', apellidopnf2:'', apellidomnf2: '',domnf2: '',telnf2: '', idcontrolnf2: '', nombrenf2control: ''},
         {nombrefam: '', apellidopfam:'', apellidomfam: '',domfam: '',telfam: '', idcontrolfam: '', nombrefamcontrol: ''},
      ],
      egrydatban: {
         sueldomens: 0,oingresos: 0,esoingresos: '',ncuentabanco: '',nombrebanco: '',clabebanco: '', supertotal: '', bpuntualidad: '', basistencia: '',
         valesdespensa: '', impuestos: '', fahorro: '', infonavit: '', retimss: '', pfahorro: 0, cajaahorro: 0, dseguro: 0, extraordinario: 0, palimentaria: 0,
         extra1: ''
      },
      datosLaborales: {
         empresa: '',antiguedad:'',puesto: '',jefe:'',formaPago: 'Quincenal',psemanal: '',pquincenal: '', plazocredito: ''
      },
      datosCredito: {
         monto: '',destino: '',anualidad: 'NO',montoanualidad: ''
      },
      kyc: {
        public_charge: 'NO', public_charge_det: '', relative_charge: 'NO', relative_charge_det: '', benefit: 'NO', benefit_detail: '', responsible: 'NO', responsible_detail: ''
        }
     
   }
  capturaCp: boolean;
  constructor( private aws: AwsService, 
               private _router: ActivatedRoute,
               public router: Router,
               private _formBuilder: FormBuilder ) {
  }
  //mandar el credito a PR
  ngOnInit(): void {
    swal2.showLoading();
    this.idu = this._router.snapshot.paramMap.get('id');
    this.aws.getInfoRefPers(this.idu).subscribe(r => {
      for (const prop in r) {
         if (r[prop].reference_type.toUpperCase() === 'NO FAMILIAR' && this.regUsuario.refPers[0].nombrenf1 === '') {
            this.regUsuario.refPers[0].nombrenf1 = r[prop].first_name
            this.regUsuario.refPers[0].nombrenf1control = r[prop].first_name
            this.regUsuario.refPers[0].apellidopnf1 = r[prop].last_name
            this.regUsuario.refPers[0].apellidomnf1 = r[prop].second_last_name
            this.regUsuario.refPers[0].domnf1 = r[prop].address
            this.regUsuario.refPers[0].telnf1 = r[prop].phone
         } else if (r[prop].reference_type.toUpperCase() === 'NO FAMILIAR') {
            this.regUsuario.refPers[1].nombrenf2 = r[prop].first_name
            this.regUsuario.refPers[1].nombrenf2control = r[prop].first_name
            this.regUsuario.refPers[1].apellidopnf2 = r[prop].last_name
            this.regUsuario.refPers[1].apellidomnf2 = r[prop].second_last_name
            this.regUsuario.refPers[1].domnf2 = r[prop].address
            this.regUsuario.refPers[1].telnf2 = r[prop].phone
         } else if (r[prop].reference_type.toUpperCase() === 'FAMILIAR') {
            this.regUsuario.refPers[2].nombrefam = r[prop].first_name
            this.regUsuario.refPers[2].nombrefamcontrol = r[prop].first_name
            this.regUsuario.refPers[2].apellidopfam = r[prop].last_name
            this.regUsuario.refPers[2].apellidomfam = r[prop].second_last_name
            this.regUsuario.refPers[2].domfam = r[prop].address
            this.regUsuario.refPers[2].telfam = r[prop].phone
         }
      }
    })
    this.aws.getInfoRegEmp(this.idu).subscribe(r => { this.usuario = r;
      if (r.length === 0) {
         swal2.fire({
            title: 'La información del usuario no fue encontrada', 
            text: '', 
            icon: 'info',
            showConfirmButton: true,
            allowOutsideClick: false
          }).then(res => {
            if(res.value){
              this.router.navigate(['/modregemp']);
            }
          });
         return
      }
   this.aws.getCompanyes().subscribe(resp => {this.companyes = resp;
      const i = this.companyes.findIndex(e => e.id === r[0].customer_company);
      if (i > -1) {
  
   this.regUsuario.datosEmpleoAct.arrdata.push(this.companyes[i].id);
   this.regUsuario.datosEmpleoAct.arrdata.push(this.companyes[i].rfc);
   this.regUsuario.datosEmpleoAct.arrdata.push(this.companyes[i].business_name);
   this.regUsuario.datosEmpleoAct.companyid = this.companyes[i].id;
   this.regUsuario.datosEmpleoAct.rfcemp = this.companyes[i].rfc;
   this.regUsuario.datosEmpleoAct.empresadat = this.companyes[i].business_name;
}
   });
   //console.log(r[0].contributor_id)
   this.aws.getContributorDocuments(r[0].contributor_id).subscribe(r => {
      this.contributordocuments = r.data;
      //console.log(this.contributordocuments);
      for (let i in this.contributordocuments) {
         if (this.contributordocuments[i].attributes.name === 'Comprobantes de ingresos del empleado') {
            this.contributorNominaId = this.contributordocuments[i].attributes.contributor_id;
            this.contributorNominaDocId = this.contributordocuments[i].attributes.id;
            this.contributorNominaDocs = (this.contributordocuments[i].attributes.url).split('*');
            this.contributorNominaDocs = this.contributorNominaDocs.splice(1,this.contributorNominaDocs.length);
            //console.log(this.contributorNominaDocs)
            this.contributordocuments.splice(parseInt(i), 1);
         }
      }
   this.contributordocuments = r.data;
   })
    this.regUsuario.datosEspeciales.person_id = r[0].contributor_person;
    this.regUsuario.datosPersonales.nombre = r[0].first_name;
    this.regUsuario.datosPersonales.sexo = r[0].gender; 
    this.regUsuario.datosPersonales.fechanacimiento = r[0].birthdate; 
   // this.regUsuario.datosPersonales.edad = r[0].name; 
    this.regUsuario.datosPersonales.pais = r[0].birth_country; 
    this.regUsuario.datosPersonales.paisres = r[0].birthplace; 
    this.regUsuario.datosPersonales.nacionalidad = r[0].nationality; 
    this.regUsuario.datosPersonales.RFC = r[0].rfc; 
    this.regUsuario.datosPersonales.CURP = r[0].curp;
    this.regUsuario.datosPersonales.identificacion = r[0].id_type;
    this.regUsuario.datosPersonales.nidentificacion = r[0].identification; 
    // this.regUsuario.datosPersonales.telefono = r[0].name; 
    this.regUsuario.datosPersonales.casado = r[0].martial_status; 
    this.regUsuario.datosPersonales.regimen = r[0].martial_regime;
    if (r[0].minior_dependents > 0 || r[0].senior_dependents > 0 ) {
      this.regUsuario.datosPersonales.dependientes = 'SI'
      this.regUsuario.datosPersonales.menores = r[0].minior_dependents; 
      this.regUsuario.datosPersonales.mayores = r[0].senior_dependents;
    }
    this.regUsuario.datosPersonales.imms = r[0].imss;
    this.regUsuario.datosPersonales.apellidom = r[0].second_last_name; 
    this.regUsuario.datosPersonales.apellidop = r[0].last_name;

    this.regUsuario.datosDomicilio.calle = r[0].street;
    this.regUsuario.datosDomicilio.nexterior = r[0].external_number;
    this.regUsuario.datosDomicilio.ninterior = r[0].apartment_number;
    this.regUsuario.datosDomicilio.colonia = r[0].suburb;
    this.regUsuario.datosDomicilio.cp = r[0].postal_code;
    this.getPais(r[0].pais, r[0].estado, r[0].municipio);
    this.getColonias(r[0].postal_code);
    //this.buscaMun(r[0].state_id);
    //this.regUsuario.datosDomicilio.delymun = r[0].municipality_id;
    //this.regUsuario.datosDomicilio.ciudad = r[0].state_id;
    
    this.regUsuario.datosDomicilio.tvivienda = r[0].housing_type;
    this.regUsuario.datosDomicilio.telparticular = r[0].phone;
    this.regUsuario.datosDomicilio.telmovil = r[0].mobile;
    this.regUsuario.datosDomicilio.mailface = r[0].email;
    this.regUsuario.datosDomicilio.referencia = r[0].address_reference;
    this.regUsuario.datosDomicilio.house_rent = r[0].house_rent;

    this.regUsuario.datosEmpleoAct.rfcemp = r[0].company_rfc;
    this.regUsuario.datosEmpleoAct.companyid = r[0].company_id;
    this.regUsuario.datosEmpleoAct.extra3 = r[0].customer_extra3;

    this.regUsuario.egrydatban.nombrebanco = r[0].bank;
    this.regUsuario.egrydatban.ncuentabanco = r[0].account_number;
    this.regUsuario.egrydatban.clabebanco = r[0].clabe;
    this.regUsuario.egrydatban.sueldomens = r[0].salary;
    this.regUsuario.egrydatban.oingresos = r[0].other_income;
    this.regUsuario.egrydatban.supertotal = r[0].total_income;
    this.regUsuario.egrydatban.bpuntualidad = r[0].ontime_bonus;
    this.regUsuario.egrydatban.basistencia = r[0].assist_bonus;
    this.regUsuario.egrydatban.valesdespensa = r[0].food_vouchers;
    this.regUsuario.egrydatban.impuestos = r[0].taxes;
    this.regUsuario.egrydatban.retimss = r[0].imms;
    this.regUsuario.egrydatban.fahorro = r[0].savings_found;
    this.regUsuario.egrydatban.infonavit = r[0].infonavit;
    this.regUsuario.egrydatban.pfahorro = r[0].savings_found_loand;
    this.regUsuario.egrydatban.cajaahorro = r[0].savings_bank;
    this.regUsuario.egrydatban.dseguro = r[0].insurance_discount;
    this.regUsuario.egrydatban.extraordinario = r[0].extra_expenses;
    this.regUsuario.egrydatban.palimentaria = r[0].child_support;
    this.regUsuario.egrydatban.extra1 = r[0].contributor_extra1;

    this.regUsuario.datosLaborales.antiguedad = r[0].seniority;
    this.regUsuario.datosLaborales.jefe = r[0].immediate_superior;
    this.regUsuario.datosLaborales.formaPago = r[0].salary_period;

    this.regUsuario.kyc.public_charge = r[0].public_charge;
    this.regUsuario.kyc.public_charge_det = r[0].public_charge_det;
    this.regUsuario.kyc.relative_charge = r[0].relative_charge;
    this.regUsuario.kyc.relative_charge_det = r[0].relative_charge_det;
    this.regUsuario.kyc.benefit = r[0].benefit;
    this.regUsuario.kyc.benefit_detail = r[0].benefit_detail;
    this.regUsuario.kyc.responsible = r[0].responsible;
    this.regUsuario.kyc.responsible_detail = r[0].responsible_detail;
    })
    //this.aws.getStates().subscribe( resp => { this.states = resp; } );
    this.aws.getUserGender().subscribe(resp => { this.gender = resp.data})
    this.aws.getPersonBank().subscribe(resp => { this.bank = resp.data})
    this.aws.getCompanyes().subscribe(resp => {this.companyes = resp;});
    // this.aws.getKeyAlsuper().subscribe(r => {this.keycompextra3 = r.data;})
    this.aws.getKeyYucGovEmployees().subscribe(r => {this.keycompextra3 = r.data;})
    swal2.close()
  }

  async buscakeycompany(namecompany) {
   if (namecompany.toLowerCase() === 'alsuper') {
      this.aws.getKeyAlsuper().subscribe(r => {this.keycompextra3 = r.data;})
   } else if(namecompany.toLowerCase() === 'yucatan_gov') {
      this.aws.getKeyAlsuper().subscribe(r => {this.keycompextra3 = r.data;})
   } else {
      this.keycompextra3 = [];
      this.regUsuario.datosEmpleoAct.extra3 = null
   }
  }
  
  firstFormGroup = this._formBuilder.group({
    
  });
  domFormGroup = this._formBuilder.group({
    
  });
  egrcredFormGroup = this._formBuilder.group({
    
  });
  datempFormGroup = this._formBuilder.group({
   
  });
  secondFormGroup = this._formBuilder.group({
    
  });
  tirdFormGroup = this._formBuilder.group({
    
  });
  /* getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {this.colonias = r})
   } */
   getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {
       this.colonias = r;
       this.capturaCp = false;
       if(this.colonias.length == 0){
         this.capturaCp = true;
       }
     })
   }

   buscaMun(id) {
    this.aws.getMunicipios(id).subscribe( r => {this.mun = r;} )
   }

   getPais(pais: string, estado: string, municipio: string) {
    this.aws.getCountriesD().subscribe( resp => { this.countries = resp; 
    for (const prop in resp) {
      if (pais.toLowerCase() === resp[prop].name.toLowerCase()) {
        this.regUsuario.datosDomicilio.paisdom = resp[prop].id;
        this.aws.getStatesId(resp[prop].id).subscribe( respstates => { this.states = respstates; 
        for (const prop in respstates) {
          if (estado.toLowerCase() === respstates[prop].name.toLowerCase()) {
            this.regUsuario.datosDomicilio.ciudad = respstates[prop].id;
            this.aws.getMunicipios(respstates[prop].id).subscribe( respmun => {this.mun = respmun;
            for (const prop in respmun) {
              if (municipio.toLowerCase() === respmun[prop].name.toLowerCase()) {
                this.regUsuario.datosDomicilio.delymun = respmun[prop].id;
                break;
              }
            }
            } )
            break;
          }
        }
        } );
      }
    }
    } );
   }

   fileBrowseHandler( e: any, name: string, url: string) {

      switch (name) {
         case 'Identificacion oficial INE':
           this.myForm.patchValue({ fileIne: e[0] });
           this.fileIneUrl = url;
          break;
         case 'Comprobante de domicilio':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileCompDom: e[0] });
          break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
          case 'Curp del empleado':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileCurp: e[0] });
            break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
          case 'Estado de cuenta bancario':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileEcuenta: e[0] });
             break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
          case 'XML del empleado':
           if ((e[0].type).toLowerCase().includes('xml')) {
             this.myForm.patchValue({ fileXml: e[0] });
          break;
           } else {
             swal2.fire('El archivo tiene que ser un XML','','info');
             break;
           }
           case 'Validacion rfc':
            if ((e[0].type).toLowerCase().includes('pdf')) {
              this.myForm.patchValue({ fileVrfc: e[0] });
              break;
            } else {
              swal2.fire('El archivo tiene que ser un PDF','','info');
              break;
            }
            case 'Validacion ine':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileVine: e[0] });
             break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
           case 'Constancia de situación fiscal':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileCsf: e[0] });
             break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
           case 'Listas negras':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileLnegras: e[0] });
             break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
           case 'Alta siac':
           if ((e[0].type).toLowerCase().includes('pdf')) {
             this.myForm.patchValue({ fileAsiac: e[0] });
             break;
           } else {
             swal2.fire('El archivo tiene que ser un PDF','','info');
             break;
           }
       }
    }

    async remplazaArchivos(file: any, url: string, item:any) {
      if (url != null) {
         swal2.showLoading()
         let nameFile = (url.split("/").pop()).replace(/%20/g,' ');
         let rd = await this.aws.delteFile(this.idu,nameFile);
         let r = await this.aws.uploadFileRemplazo(file, this.idu, nameFile);
         let rp =  this.aws.patchUrlreemplazo(item.attributes.contributor_id, item.attributes.id, r.Location).subscribe(r => {//console.log(r)
            swal2.fire({
               title: 'Exito', 
               text: 'Archivo reemplazado correctamente', 
               icon: 'success',
               showConfirmButton: true,
               allowOutsideClick: false
             }).then(res => {
               if(res.value){
                 window.location.reload();
               }
             });
         })
         //console.log(rp) 
      } else {
      swal2.showLoading()
      let nameFile = file.name;
      let r = await this.aws.uploadFileRemplazo(file, this.idu, nameFile);
      let rp =  this.aws.patchUrlreemplazo(item.attributes.contributor_id, item.attributes.id, r.Location).subscribe(r => {
         swal2.fire({
            title: 'Exito', 
            text: 'Archivo subido correctamente', 
            icon: 'success',
            showConfirmButton: true,
            allowOutsideClick: false
          }).then(res => {
            if(res.value){
              window.location.reload();
            }
          });
      })
      //console.log(rp) 
      }
      
    }

   async guardaCambios() {
      swal2.showLoading();
      (await this.aws.PatchPFcustomerConsulta(this.regUsuario.datosEspeciales.person_id, this.regUsuario.datosPersonales.nombre)).subscribe(
         async r =>{
         this.regUsuario.datosEspeciales.contributor_id = r.data.relations.contributors[0].id;
         this.regUsuario.datosEspeciales.customer_id = r.data.relations.customers[0].id;
         this.regUsuario.datosEspeciales.contributor_addres_id = r.data.relations.contributor_addresses[0].id;
         this.regUsuario.datosEspeciales.customer_pr_id = r.data.relations.customer_personal_references[0].id;
         for await (const i of r.data.relations.customer_personal_references) {
            if (i.attributes.first_name === this.regUsuario.refPers[0].nombrenf1control) {
              this.regUsuario.refPers[0].idcontrolnf1 = i.attributes.id
            } else if (i.attributes.first_name === this.regUsuario.refPers[1].nombrenf2control) {
              this.regUsuario.refPers[1].idcontrolnf2 = i.attributes.id
            } else if (i.attributes.first_name === this.regUsuario.refPers[2].nombrefamcontrol) {
              this.regUsuario.refPers[2].idcontrolfam = i.attributes.id
            }
        }
         (await this.aws.PatchPFcustomer(this.regUsuario)).subscribe(r => {//console.log(r)
          
            swal2.fire({
               title: 'Exito', 
               text: 'Modificación de información exitosa', 
               icon: 'success',
               showConfirmButton: true,
               allowOutsideClick: false
             }).then(res => {
               if(res.value){
                 this.router.navigate(['/modregemp']);
               }
             });
         })
         }
      ) 
   
   }

   onFileChange(event: any) {
      if ((event.target.files[0].type).toLowerCase().includes('pdf')) {
        if (event.target.files && event.target.files[0]) {
          if (this.files.length === 0) {
           this.files.push(event.target.files[0]);
           this.filesNames.push(event.target.files[0].name)
          } else {
             if (!this.filesNames.includes(event.target.files[0].name)) {
               this.files.push(event.target.files[0]);
               this.filesNames.push(event.target.files[0].name)
             }
          }
           this.myForm.patchValue({
             filesIngresos: this.files
          });
         }
      } else {
        swal2.fire('El archivo tiene que ser un PDF','','info');
      }
      
      
    }

    async subirarchivosnomina() {
     swal2.showLoading();
     let resul =   await this.calluploadmulti(this.myForm.value.filesIngresos, this.idu);
     //console.log(resul);
     this.aws.patchUrlreemplazo(this.contributorNominaId, this.contributorNominaDocId, resul[resul.length - 1]).subscribe( r => {
      swal2.fire({
         title: 'Comprobantes de nomina subidos correctamente', 
         text: '', 
         icon: 'info',
         showConfirmButton: true,
         allowOutsideClick: false
       }).then(res => {
         if(res.value){
            window.location.reload();
         }
       });
   })
    }

    async calluploadmulti(files, idu) {
      try {
        const r = await this.aws.uploadFiless(files, idu)
        return r
      } catch (error) {
        
      }
      }

   borraRecibosNomina() {
      swal2.fire({
         title: 'Desea eliminar los archivos de nomina', 
         text: 'Para subir nuevos archivos', 
         icon: 'question',
         showConfirmButton: true,
         showCancelButton: true,
         allowOutsideClick: false
       }).then(res => {
         if(res.value){
          this.aws.patchUrlreemplazo(this.contributorNominaId, this.contributorNominaDocId, '').subscribe( r => {
            swal2.fire({
               title: 'Comprobantes de nomina eliminados correctamente', 
               text: '', 
               icon: 'info',
               showConfirmButton: true,
               allowOutsideClick: false
             }).then(res => {
               if(res.value){
                  window.location.reload();
               }
             });
         })
         }
       });
   }
}
