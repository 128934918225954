import { Component, OnInit } from '@angular/core';
import { DocumentosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-tipoarchivodocumento',
  templateUrl: './tipoarchivodocumento.component.html',
  styleUrls: ['./tipoarchivodocumento.component.css']
})
export class TipoarchivodocumentoComponent implements OnInit {
  cols: any[];
  archivos: any[];
  constructor( private documentosService: DocumentosService ) { }

  ngOnInit(): void {

    swal2.showLoading();
    this.documentosService.getTiposArchivos().subscribe( resp => { this.archivos = resp; swal2.close(); } );
    this.cols = [
      { field: 'name', header: 'Nombre' },
      { field: 'description', header: 'Descripcion' },
      { field: 'customer_type', header: 'Tipo de cliente' },
      { field: 'funder_type', header: 'Tipo de fondeador' },
      { field: 'herramientas', header: 'Herramientas' }
      
      ];
  }

}
