import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-creaservicioexterno',
  templateUrl: './creaservicioexterno.component.html',
  styles: []
})
export class CreaServicioExternoComponent implements OnInit {

  forma: FormGroup;
  ido: string;
  service: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private documentosService: DocumentosService,
    public router: Router
  ) { }


  ngOnInit() {
    this.forma = new FormGroup({
      Proveedor: new FormControl( null , Validators.required ),
      Usuario: new FormControl( null , Validators.required ),
      URL: new FormControl( null , Validators.required )

    } );
  }


  CreaService() {

    // Obtener el elemento por el id
    const Proveedor: any = document.getElementById('Proveedor');
    const Usuario: any = document.getElementById('Usuario');
    const Api_key: any = document.getElementById('Api_key');
    const Token: any = document.getElementById('Token');
    const URL: any = document.getElementById('URL');
    const Regla: any = document.getElementById('Regla');
    // Obtener el valor de la opción seleccionada

    const valorProveedor = Proveedor.value;
    const valorUsuario = Usuario.value;
    const valorApikey = Api_key.value;
    const valorToken = Token.value;
    const valorURL = URL.value;
    const valorRegla = Regla.value;

    const params = {
      supplier: valorProveedor,
      user: valorUsuario,
      api_key: valorApikey,
      token: valorToken,
      url: valorURL,
      rule: valorRegla,
      secret_key: ''
    }

    this.documentosService.crearExtService( params ).subscribe( () => {
    Swal.fire({
      title: 'Creacion de servicio exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/serviciosexternos']);
      }

    } );

  }, (err) => {             console.clear();
    // console.log(err);
                            Swal.fire(
                              'Error al modificar servicio',
                              'Error',
                              'error'
                           );
                        } );

  }


}
