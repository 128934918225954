import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';

@Component({
  selector: 'app-pendientes-afiliacion',
  templateUrl: './pendientes-afiliacion.component.html',
  styleUrls: ['./pendientes-afiliacion.component.css']
})
export class PendientesAfiliacionComponent implements OnInit {

  usuarios: any[] = [];
  cols: any[];
  nombre = '';

  constructor(private usuariosservice: UsuarioService,
              public router: Router ) { }

  ngOnInit(): void {
    this.usuariosservice.getUsuariosSinCustomer().subscribe(resp => {
      this.usuarios = resp;
    })
    /* this.usuariosservice.getUsuarios().subscribe( resp => { this.usuarios = resp;
      for (const prop in this.usuarios) {
        if (this.usuarios[prop].role_id !== null) {
          this.usuariosservice.getrol(this.usuarios[prop].role_id).subscribe(r => {
            this.usuarios[prop].nombrerol = r.data.attributes.name;
            })
        } else {
          this.usuarios[prop].nombrerol = 'Sin rol asignado';
        }
        
      }
      } ); */
      this.cols = [
  
        { field: 'name', header: 'Nombre' },
        { field: 'email', header: 'Correo' },
        { field: 'job', header: 'Puesto' },
        { field: 'gender', header: 'Genero' },
        { field: 'status', header: 'Estatus' },
        // { field: 'nombrerol', header: 'Rol' },
    ];
  }

  seleccionarUsuario(user){
    this.router.navigate([`/registroemp/${user.id}`]);
  }

}
