import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alta-empleados',
  templateUrl: './alta-empleados.component.html',
  styleUrls: ['./alta-empleados.component.css']
})
export class AltaEmpleadosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  dragEnter(id: string) {
    document.getElementById(id).style.border = "dashed 2px blue";
  }
  dragLeave(id: string) {
      document.getElementById(id).style.border = "dashed 1px #979797";
  }
  drop(id: string) {
    document.getElementById(id).style.border = "dashed 1px #979797";
  }

  fileBrowseHandler( e: any ) {
    console.log('documentillo', e[0]);

    /* this.parseExcel = function(file) {
      var reader = new FileReader();
  
      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, {
          type: 'binary'
        });
  
        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          var json_object = JSON.stringify(XL_row_object);
          console.log(json_object);
  
        })
  
      };
  
      reader.onerror = function(ex) {
        console.log(ex);
      };
  
      reader.readAsBinaryString(file);
    }; */

    /* readXlsxFile(e[0]).then((rows) => {
      console.log(rows);
      // `rows` is an array of rows
      // each row being an array of cells.
    }) */
  }

}
