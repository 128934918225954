import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RolesService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-crearrol',
  templateUrl: './crearrol.component.html',
  styles: []
})
export class CrearRolComponent implements OnInit {

  forma: FormGroup;

  constructor(
    private route: ActivatedRoute,
    public _rolService: RolesService,
    public router: Router
  ) { }


  ngOnInit() {


      this.forma = new FormGroup({
        Descripcion: new FormControl( null , Validators.required ),
        Name: new FormControl( null , Validators.required )

      } );

  }


  registrarRol() {
    Swal.showLoading();
  //  const rolName =  this.forma.value.Name;
  //  const rolDesc =  this.forma.value.Descripcion;

    const params = {
      token: '',
      secret_key: '',
      name:  this.forma.value.Name,
      description:  this.forma.value.Descripcion,
    };

    this._rolService.crearRol(params).subscribe( resp => {
    Swal.fire({
      title: 'Creacion de rol exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/roles']);
      }

    } );

  }, (err) => {             console.clear();
                            Swal.fire(
                              'Error al crear Rol',
                              'Error',
                              'error'
                           );
                        } );

  }


}
