import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  AwsService,
  SignatoryesService,
  UsuarioService,
  ListasService,
  OpcionesService,
  RolesService,
  ParametrosGeneralesService,
  OpcionesusuariosService,
  RolesOpcionesService,
  PrivilegiosUsuariosService,
  ContribuyentesService,
  RegistrovalidacionService,
  CreditosService,
  TuidentidadService,
  LoginGuardGuard,
  UsuariosPcGuard,
  OportunidadesGuard,
  ListasPcGuard,
  RolesPcGuard,
  OpcionesPcGuard,
  ParametrosGeneralesPcGuard,
  OpcionesUsuariosPcGuard,
  RolesOpcionesPcGuard,
  PrivilegiosUsuariosPcGuard,
  DocumentosService,
  ServiciosExternosPcGuard,
  TiposArchivosPcGuard,
  DocumentoPcGuard,
  CondicionesPcGuard,
  PeriodosPagoPcGuard,
  CalificacionCreditosPcGuard,
  TarifasPcGuard,
  RegEmpGuard,
  ModRegEmpPcGuard
 } from './service.index';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AwsService,
    SignatoryesService,
    UsuarioService,
    ListasService,
    OpcionesService,
    RolesService,
    ParametrosGeneralesService,
    OpcionesusuariosService,
    RolesOpcionesService,
    PrivilegiosUsuariosService,
    ContribuyentesService,
    RegistrovalidacionService,
    CreditosService,
    TuidentidadService,
    LoginGuardGuard,
    UsuariosPcGuard,
    OportunidadesGuard,
    ListasPcGuard,
    RolesPcGuard,
    OpcionesPcGuard,
    ParametrosGeneralesPcGuard,
    OpcionesUsuariosPcGuard,
    RolesOpcionesPcGuard,
    PrivilegiosUsuariosPcGuard,
    DocumentosService,
    ServiciosExternosPcGuard,
    TiposArchivosPcGuard,
    DocumentoPcGuard,
    CondicionesPcGuard,
    PeriodosPagoPcGuard,
    CalificacionCreditosPcGuard,
    TarifasPcGuard,
    RegEmpGuard,
    ModRegEmpPcGuard
  ],
  declarations: []
})
export class ServiceModule { }
