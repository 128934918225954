import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';
import { ContribuyentesService } from '../../../services/registrocontribuyente/contribuyente.service';
import { ListasService } from '../../../services/listas/listas.service';
import { AwsService } from '../../../services/awsser/aws.service';

@Component({
  selector: 'app-alta-cadenas',
  templateUrl: './alta-cadenas.component.html',
  styles: [
  ]
})
export class AltaCadenasComponent implements OnInit {

  clavesPF = [];
  clavesPM = [];
  personVal = '';
  municipios = [];
  estados = [];
  generos = [];
  lists = [];
  maritalStatus = [];
  regimenFiscal = [];
  addressType = [];
  suburbType = [];
  companyRate = [];
  banks = [];
  colonias = [];

  constructor(
    private route: ActivatedRoute,
    public contribuyentesService: ContribuyentesService,
    public listasService: ListasService,
    public router: Router,
    private aws: AwsService
  ) { }


  ngOnInit() {
    this.clavesPF = [
      {key: 'first_name', nombre: 'Nombre', type: 'text', group: 'person'},
      {key: 'last_name', nombre: 'Apellido Paterno', type: 'text', group: 'person'},
      {key: 'second_last_name', nombre: 'Apellido Materno', type: 'text', group: 'person'},
      {key: 'rfc', nombre: 'RFC', type: 'text', group: 'person'},
      {key: 'curp', nombre: 'CURP', type: 'text', group: 'person'},
      {key: 'imss', nombre: 'IMSS', type: 'text', group: 'person'},
      {key: 'gender', nombre: 'Genero', type: 'list', group: 'person'},
      {key: 'nationality', nombre: 'Nacionalidad', type: 'text', group: 'person'},
      {key: 'birth_country', nombre: 'País de nacimiento', type: 'text', group: 'person'},
      {key: 'birthplace', nombre: 'Ciudad de nacimiento', type: 'text', group: 'person'},
      {key: 'birthdate', nombre: 'Fecha de nacimiento', type: 'date', group: 'person'},
      {key: 'martial_status', nombre: 'Estado civil', type: 'list', group: 'person'},
      {key: 'id_type', nombre: 'Tipo de identificación', type: 'list', group: 'person'}, //-------------
      {key: 'identification', nombre: 'Identificación', type: 'text', group: 'person'},
      {key: 'phone', nombre: 'Teléfono', type: 'text', group: 'person'},
      {key: 'mobile', nombre: 'Celular', type: 'text', group: 'person'},
      {key: 'email', nombre: 'Correo electrónico', type: 'email', group: 'person'},
      {key: 'contributor_type', nombre: 'Tipo de contribuidor', type: 'list', group: 'contributor'}, //------
      {key: 'bank', nombre: 'Banco', type: 'list', group: 'contributor'},
      {key: 'account_number', nombre: 'Cuenta bancaria', type: 'text', group: 'contributor'},
      {key: 'clabe', nombre: 'Cuenta CLABE', type: 'text', group: 'contributor'},
      {key: 'state_id', nombre: 'Estado', type: 'extern list', group: 'contributor_address'},
      {key: 'municipality_id', nombre: 'Municipio', type: 'extern list', group: 'contributor_address'},
      {key: 'address_type', nombre: 'Tipo de dirección', type: 'list', group: 'contributor_address'},
      {key: 'street', nombre: 'Calle', type: 'text', group: 'contributor_address'},
      {key: 'external_number', nombre: 'Número exterior', type: 'text', group: 'contributor_address'},
      {key: 'apartment_number', nombre: 'Número interior', type: 'text', group: 'contributor_address'},
      {key: 'postal_code', nombre: 'Código postal', type: 'text', group: 'contributor_address'},
      {key: 'suburb', nombre: 'Colonia', type: 'list', group: 'contributor_address'},
      {key: 'suburb_type', nombre: 'Tipo de residencia', type: 'list', group: 'contributor_address'},
      {key: 'address_reference', nombre: 'Referencia', type: 'text', group: 'contributor_address'},
      {key: 'business_name', nombre: 'Nombre de la empresa', type: 'text', group: 'company'},
      {key: 'start_date', nombre: 'Fecha de inicio de la empresa', type: 'date', group: 'company'},
      {key: 'company_rate', nombre: 'Origen', type: 'list', group: 'company'},
      {key: 'credit_limit', nombre: 'Límite de crédito', type: 'number', group: 'company'},
      {key: 'document', nombre: 'Documento', type: 'file', group: 'company'},
      {key: 'credit_available', nombre: 'Crédito disponible', type: 'text', group: 'company'},
      {key: 'sector', nombre: 'Sector', type: 'text', group: 'company'},
      {key: 'subsector', nombre: 'Subsector', type: 'text', group: 'company'},
    ];

    this.clavesPM = [
      {key: 'rfc', nombre: 'RFC', type: 'text', group: 'legal_entity'},
      // {key: 'rug', nombre: 'RUG', type: 'text', group: 'legal_entity'},
      {key: 'business_name', nombre: 'Nombre de la empresa', type: 'text', group: 'legal_entity'},
      {key: 'phone', nombre: 'Teléfono', type: 'text', group: 'legal_entity'},
      {key: 'mobile', nombre: 'Celular', type: 'text', group: 'legal_entity'},
      {key: 'email', nombre: 'Correo electrónico', type: 'email', group: 'legal_entity'},
      {key: 'business_email', nombre: 'Correo electrónico de la empresa', type: 'email', group: 'legal_entity'},
      // {key: 'fiel', nombre: 'Fiel', type: 'text', group: 'legal_entity'},
      {key: 'contributor_type', nombre: 'Tipo de contribuidor', type: 'list', group: 'contributor'},//---
      {key: 'bank', nombre: 'Banco', type: 'list', group: 'contributor'},
      {key: 'account_number', nombre: 'Cuenta bancaria', type: 'text', group: 'contributor'},
      {key: 'clabe', nombre: 'Cuenta CLABE', type: 'text', group: 'contributor'},
      {key: 'state_id', nombre: 'Estado', type: 'extern list', group: 'contributor_address'},
      {key: 'municipality_id', nombre: 'Municipio', type: 'extern list', group: 'contributor_address'},
      {key: 'address_type', nombre: 'Tipo de dirección', type: 'list', group: 'contributor_address'},
      {key: 'street', nombre: 'Calle', type: 'text', group: 'contributor_address'},
      {key: 'external_number', nombre: 'Número exterior', type: 'text', group: 'contributor_address'},
      {key: 'apartment_number', nombre: 'Número interior', type: 'text', group: 'contributor_address'},
      {key: 'suburb_type', nombre: 'Tipo de residencia', type: 'list', group: 'contributor_address'},
      {key: 'postal_code', nombre: 'Código postal', type: 'text', group: 'contributor_address'},
      {key: 'suburb', nombre: 'Colonia', type: 'list', group: 'contributor_address'},
      {key: 'address_reference', nombre: 'Referencia', type: 'text', group: 'contributor_address'},
      {key: 'business_name', nombre: 'Nombre de la empresa', type: 'text', group: 'company'},
      {key: 'start_date', nombre: 'Fecha inicio de la empresa', type: 'date', group: 'company'},
      {key: 'company_rate', nombre: 'Origen', type: 'list', group: 'company'},
      {key: 'credit_limit', nombre: 'Límite de crédito', type: 'number', group: 'company'},
      {key: 'credit_available', nombre: 'Crédito disponible', type: 'text', group: 'company'},
      {key: 'document', nombre: 'Documento', type: 'file', group: 'company'},
      {key: 'sector', nombre: 'Sector', type: 'text', group: 'company'},
      {key: 'subsector', nombre: 'Subsector', type: 'text', group: 'company'},
    ];

    this.contribuyentesService.getCountries().subscribe(countries => {
      countries.forEach(country => {
        if(country.sortname === 'MX'){
          this.contribuyentesService.getStatesById(country.id).subscribe(states => {
            states.forEach(state => {
              this.estados.push(state);
            })
          })
        }
      })
    })

    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
      this.generos = this.lists.filter(p => p.domain === 'USER_GENDER');
      this.maritalStatus = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
      this.regimenFiscal = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
      this.addressType = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
      this.suburbType = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
      this.companyRate = this.lists.filter(p => p.domain === 'COMPANY_RATE');
    })

    this.aws.getPersonBank().subscribe(resp => { this.banks = resp.data })

  }

  personValue(value){
    this.personVal = value;
  }

  getMunicipality(){
    this.municipios = [];
    this.contribuyentesService.getMunicipios((document.getElementById('state_id') as HTMLInputElement).value)
      .subscribe(municipios => {
        municipios.forEach(municipio => {
          this.municipios.push(municipio);
        })
      })
  }

  getColonias(event) {
    this.aws.getColoniasxcp(event).subscribe(r => {this.colonias = r; console.log(this.colonias)})
   }

  registrarOpcion() {
    swal2.showLoading();
    let params = {};
    if(this.personVal === 'PF'){
      let person = this.clavesPF.filter(clave => clave.group === 'person');
      params['person'] = {};
      params['person']['id_type'] = 'INE';
      params['person']['fiscal_regime'] = this.personVal;
      person.forEach(p => {
        try{
          params['person'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key);
        }
      })
      let contributor = this.clavesPF.filter(clave => clave.group === 'contributor');
      params['contributor'] = {};
      params['contributor']['contributor_type'] = 'PF'
      contributor.forEach(p => {
        try{
          params['contributor'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key);
        }
      })
      let contributor_address = this.clavesPF.filter(clave => clave.group === 'contributor_address');
      params['contributor_address'] = {};
      contributor_address.forEach(p => {
        try{
          params['contributor_address'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key);
        }
      })
      let company = this.clavesPF.filter(clave => clave.group === 'company');
      params['company'] = {};
      params['company']['document'] = '';
      company.forEach(p => {
        try{
          params['company'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key);
        }
      })
    }else if(this.personVal === 'PM'){
      let legal_entity = this.clavesPM.filter(clave => clave.group === 'legal_entity');
      params['legal_entity'] = {};
      params['legal_entity']['fiscal_regime'] = this.personVal;
      legal_entity.forEach(p => {
        try{
          params['legal_entity'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key);
        }
      })
      let contributor = this.clavesPM.filter(clave => clave.group === 'contributor');
      params['contributor'] = {};
      params['contributor']['contributor_type'] = 'PM';
      contributor.forEach(p => {
        try{
          params['contributor'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key, (document.getElementById(p.key) as HTMLInputElement));
        }
      })
      let contributor_address = this.clavesPM.filter(clave => clave.group === 'contributor_address');
      params['contributor_address'] = {};
      contributor_address.forEach(p => {
        try{
          params['contributor_address'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value
        }catch(error){
          console.log(p.key);
        }
      })
      let company = this.clavesPM.filter(clave => clave.group === 'company');
      params['company'] = {};
      params['company']['document'] = '';
      company.forEach(p => {
        try{
          params['company'][p.key] = (document.getElementById(p.key) as HTMLInputElement).value;
        }catch(error){
          console.log(p.key);
        }
      })
    }

    /*
    ----------------
    faltan el id_type que se supone que viene de lista, hay que crearlo
    contributor_type igual
    .....document se manda vacio, se pone en el back........
    ---------------
    */
    
    this.contribuyentesService.crearCadena(params).subscribe( () => {
      swal2.close();
      Swal.fire({
        title: 'Creacion de cadena exitosa',
        text: '',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.router.navigate(['/panelcontrol/empleadosCadenas']);
        }

      } );

    }, (err) => {             console.log(err);
                              swal2.close();
                              Swal.fire(
                                'Error al crear Opcion',
                                'Error',
                                'error'
                            );
                          } ); 

  }

}
