import { Component, OnInit } from '@angular/core';
import { RegistrovalidacionService } from '../../services/registrovalidacion/registrovalidacion.service';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styles: []
})
export class RegistroComponent implements OnInit {
  idu = '';
  muestraregistrousuario: boolean;
  muestradocumentos: boolean;
  constructor( private registrovalidacion: RegistrovalidacionService ) { }

  ngOnInit() {
    this.muestraregistrousuario = true;
    this.muestradocumentos = true;
    this.idu = '';
    this.idu = localStorage.getItem('uid');
    this.registrovalidacion.getusuario(this.idu).subscribe( usuario => {
    if (usuario.data.relations.customers.length > 0) {
      this.muestraregistrousuario = false;
      this.muestradocumentos = false;
    } else if ( usuario.data.relations.funders.length > 0 ) {
      this.muestraregistrousuario = false;
      this.muestradocumentos = false;
    }
    } )
  }
}
