import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizadocumento',
  templateUrl: './actualizadocumento.component.html',
  styles: []
})
export class ActualizaDocumentoComponent implements OnInit {

  forma: FormGroup;
  ido: string;
  service: any[] = [];
  doc: any[] = [];
  servicefil: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private documentosService: DocumentosService,
    public router: Router
  ) { }


  ngOnInit() {
    this.ido = this.route.snapshot.paramMap.get('id');
    this.forma = new FormGroup({
      Tdocumento: new FormControl( null , Validators.required ),
      Nombre: new FormControl( null , Validators.required ),
      Descripcion: new FormControl( null , Validators.required ),
      Validacion: new FormControl( null ),
      Sexterno: new FormControl( null )
    } );
    this.documentosService.getDocumento(this.ido).subscribe( resp => {this.doc = resp; 
      this.documentosService.getExtServices().subscribe( resp => {this.service = resp; 
      for (const prop in this.service) {
        if (this.service[prop].id === this.doc[0].ext_service_id) {
         // console.log('no tomar en cuenta');
        } else {
          this.servicefil.push(this.service[prop]);
        }
      }
      });
      if ( this.doc[0].ext_service_id !== null ) {
        if(this.doc[0].ext_service_id.length > 4) {
          this.documentosService.getExtService( this.doc[0].ext_service_id ).subscribe( resp => this.doc[0].ext_service_name = resp[0].supplier )
        }
        
      }
    } )
  }


  ActualizaDocumento() {

    const Tdocumento: any = (document.getElementById('Tdocumento')as HTMLInputElement).value;
    const Sexterno: any = (document.getElementById('Sexterno')as HTMLInputElement).value;
    const Nombre: any = (document.getElementById('Nombre')as HTMLInputElement).value;
    const Descripcion: any = (document.getElementById('Descripcion')as HTMLInputElement).value;
    const Validacion: any = (document.getElementById('Validacion')as HTMLInputElement).value;

    let params = {
      document_type: Tdocumento,
      name: Nombre,
      description: Descripcion,
      validation: Validacion,
      ext_service_id: Sexterno,
      token: '',
      secret_key: ''
    }

    if ( Sexterno === "" ) {
        params.ext_service_id = null;
    }

    this.documentosService.actualizaDocumento(this.ido, params ).subscribe( () => {
    Swal.fire({
      title: 'Actualizacion de documento exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/documento']);
      }

    } );

  }, (err) => {             console.clear();
    // console.log(err);
                            Swal.fire(
                              'Error al actualizar el documento',
                              'Error',
                              'error'
                           );
                        } );

  } 


}
