import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { UsuarioService } from '../../services/service.index';
import swal2 from 'sweetalert2';
import { element } from 'protractor';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    idr: string;
    rol: string;
    idu: string;
    nombreusuario: string;
    estalogueado: boolean;
    roloptions: any[] = [];
    constructor(public location: Location, private router: Router, private usuarioservice: UsuarioService) {
    }

    ngOnInit() {
        this.nombreusuario = localStorage.getItem('nombreuser')
       let element = document.getElementById('nombreusuario') 
       element.innerHTML = this.nombreusuario
      swal2.showLoading();
      this.idu = localStorage.getItem('uid');
      this.estalogueado = this.usuarioservice.estaLogueado();
      this.idr = localStorage.getItem('rid');
      if (this.idr !== 'null' && localStorage.getItem('rid') ) {
        this.getrol(this.idr);
      } else {
        this.rol = 'sin rol';
      }
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
     swal2.close();
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === '#/home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '#/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    getrol(rid) {
        this.usuarioservice.getrol(rid).subscribe( resp => {this.rol = resp.data.attributes.name;
            for (const prop in resp.data.relations.options) {
                this.roloptions.push(btoa(resp.data.relations.options[prop].attributes.name));
              }
              localStorage.setItem('data', JSON.stringify(this.roloptions));  
        } );
        }

    logout() {
   
   swal2.fire({
    title: 'Cerrar Sesion?',
    text: `Se cerrara la sesion actual`,
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
  }). then ( resp => {
    if ( resp.value) {
        swal2.showLoading();
        this.router.navigate(['/home']).then(() => {
                                                    this.usuarioservice.logout();
                                                    window.location.reload();

  //swal2.close();
 });
    }
  });


    }

    llevapaginarol() {
        if (this.rol === 'sin rol') {
            this.router.navigate(['/registro']);
        } else if ( this.rol === 'Clientes' && localStorage.getItem('uid') ) {
            this.router.navigate(['/maincliente', this.idu]);
        }
    }
}
