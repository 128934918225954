import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CreditosService, UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-yucatan',
  templateUrl: './yucatan.component.html',
  styleUrls: ['../creditanalisis.component.scss']
})
export class YucatanComponent implements OnInit {
  gov_customer_id = '';
  id_credito = '';
  gov_customer = {};
  pagos = [];
  credito = {};
  plazo = '';
  payment_capacity = 0;
  customer = {};
  showModal = false;
  payment_capacity_percentage: number;

  constructor(private usuarioService: UsuarioService,
              private routee: ActivatedRoute,
              private creditosService: CreditosService,
              public router: Router
  ) { }

  ngOnInit(): void {
    this.gov_customer_id = this.routee.snapshot.paramMap.get('gov_customer_id');
    this.id_credito = this.routee.snapshot.paramMap.get('id_credito');
    var observableCredito = this.creditosService.getCredit(this.id_credito);
    var observableCustomer = this.usuarioService.getGovCustomer(this.gov_customer_id);
    var observableTerms = this.creditosService.getCondiciones();
    forkJoin([observableCredito, observableCustomer, observableTerms]).subscribe(([credito, customer, terms]) => {
      this.customer = customer;
      this.credito = credito['data']['attributes'];
      var p = terms.find(t => t.id == this.credito['term_id']);
      this.plazo = p.key + ' ' + p.term_type;
      this.pagos = this.crearArreglo(credito['data']['relations']['current_payments']);
      this.payment_capacity_percentage = ((parseFloat(customer['data']['relations']['gov_customers'][0]['attributes']['extra_expenses']) + parseFloat(customer['data']['relations']['gov_customers'][0]['attributes']['expenses'])) * 100) / (parseFloat(customer['data']['relations']['gov_customers'][0]['attributes']['salary']) + parseFloat(customer['data']['relations']['gov_customers'][0]['attributes']['extra_income']));
      this.payment_capacity = (parseFloat(customer['data']['relations']['gov_customers'][0]['attributes']['salary']) + parseFloat(customer['data']['relations']['gov_customers'][0]['attributes']['extra_income'])) * 0.3;
    })
  }

  hideModal() {
    this.showModal = false;
  } 

  crearArreglo(objeto) {
    var arreglo = [];
    Object.keys(objeto).forEach(k => {
      const row = objeto[k].attributes;
      arreglo.push(row);
    });
    return arreglo;
  }

  confirmarRechazo(){
      swal2.fire({
        title: 'Está a punto de rechazar la solicitud' ,
        text: "Motivo de rechazo",
        input: 'text',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, rechazar crédito',
        cancelButtonText: 'Cancelar',
        showLoaderOnConfirm: true,
        inputPlaceholder: "Introduzca el motivo de rechazo",
        inputValidator: (value) => {
          if (!value) {
            return 'Favor de ingresar un motivo!'
          }
        }      
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value === "") {
            swal2.fire("Precaucion", "Favor de agregar un motivo de rechazo", "warning");
            return false
          }
          this.actualizarCredito('RZ', result.value)
        }
      })
    }

    actualizarCredito(status, nota = ''){
        this.creditosService.updateCredit(this.id_credito, {status: status, rejection_note: nota}).subscribe(resp => {
          swal2.fire({ 
            title: 'Exito', 
            text: 'Crédito actualizado', 
            icon: 'success',
            showConfirmButton: true,
            allowOutsideClick: false
          }).then(res => {
            if(status == 'AC'){
              this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
            }else{
              this.ngOnInit();
            }
          });
        }, err => {
          console.log(err);
          swal2.fire('Error', 'Algo salió mal', 'info');
        })
      }

}
