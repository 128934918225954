import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal2 from 'sweetalert2'; 

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {
  token: string;
  tokenExpiry: any[] = [];
  bandera: boolean;
  status: string;
  datosusuario: any[] = [];
  recuperapass = false;

  constructor( private route: ActivatedRoute,
               private usuarioservice: UsuarioService,
               public router: Router ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    this.status = this.route.snapshot.paramMap.get('status');
      this.usuarioservice.getTokenStatus(this.token).subscribe( resp => {
        if ( resp.status !== true ) {
          Swal2.fire(
          'El token ha expirado',
          '',
          'error'
       );
      } else {
        if (this.status == 'aceptado') {
            this.bandera = true;
            this.acceptcredit()
        } else {
            this.bandera=false;
            this.declinecredit()
            }
        }
        this.tokenExpiry = resp.status; } );   

    }
    
  acceptcredit() {
    this.usuarioservice.confirmCredit(this.token).subscribe( resp => {
              Swal2.fire(
                'Crédito Aceptado',
                '',
                'success'
             );
             setTimeout(() => {
              this.router.navigate(['/home']);
            }, 1800);
          }
           );
  }

  declinecredit() {
    this.usuarioservice.declineCredit(this.token).subscribe( resp => {                     
        Swal2.fire(
          'Crédito Rechazado con Exito',
          'Favor de comunicarse con Nomina Factor',
          'warning'
        );
        setTimeout(() => {
          this.router.navigate(['/home']);
        }, 1800);

    } );
  }
}
