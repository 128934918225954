import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { HomeModule } from './home/home.module';
import { LoginComponent } from './login/login.component';
import { OportunidadesComponent } from './pages/oportunidades/oportunidades.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './shared/sidebar/sidebar.component';

//CONTROL PANEL
import { ControlpanelModule } from './controlpanel/controlpanel.module';
import { PagesModule } from './pages/pages.module';
// import { ResetModule } from '../resetpwd/resetpwd.module'

// SIDENAV
import { ButtonModule } from 'primeng/button';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {SidebarModule} from 'primeng/sidebar';
import {MatStepperModule, } from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input'
import {MatRadioModule} from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
//SERVICES
import { ServiceModule } from './services/service.module';


//NG MODULES
import {CalendarModule} from 'primeng/calendar';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {SliderModule} from 'primeng/slider';

//CHARTS
import {ChartModule} from 'primeng/chart';
import { ResetpwdComponent } from './login/resetpwd/resetpwd.component';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { CallbackComponent } from './callback/callback.component';
import { FileCallbackComponent } from './callback/file-callback/file-callback.component';

@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    LandingComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    OportunidadesComponent,
    SidebarComponent,
    ResetpwdComponent,
    CallbackComponent,
    FileCallbackComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    ControlpanelModule,
    BrowserAnimationsModule,
    AvatarModule,
    AvatarGroupModule,
    SidebarModule,
    ButtonModule,
    ServiceModule,
    CalendarModule,
    TableModule,
    ChartModule,
    TabViewModule,
    SliderModule,
    PagesModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatExpansionModule
   // ResetModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
