import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import Swal2 from 'sweetalert2'; 
import { CreditosService } from '../../services/creditos/creditos.service';
import { isNull } from '@angular/compiler/src/output/output_ast';
import swal2 from 'sweetalert2';
import { ContribuyentesService } from 'src/app/services/service.index';
import * as moment from 'moment';

@Component({
  selector: 'app-creditanalisis',
  templateUrl: './creditanalisis.component.html',
  styleUrls: ['./creditanalisis.component.scss']
})
export class CreditAnalisisComponent implements OnInit {

  constructor(
    public route: Router,
    private routee: ActivatedRoute,
    private creditosService: CreditosService,
    public http: HttpClient,
    private contribuyentesService: ContribuyentesService) { }

token = localStorage.getItem('token');
credit_id: string;
selectedFac: any[];
router: Router;
tieneacceso = false;
cus: any;
r: any;
d: any;
d1: any;
calificacionBC: any;
buro: string;
buroStatus = true;
existeAnalisis = false;
analysisStatus = false;
buroPdfStatus = false;
credit_analysis = '';
analisis_id = '';
status_de_Buro = '';
reporte_buro_pdf = '';

credit_cp = 0;
departamental_credit = 0;
credit_lp = 0;
car_credit = 0;
mortagage_loan = 0;
other_credits = 0;
debt_cp = 0;
departamentalc_debt = 0;
personalc_debt = 0;
car_debt = 0;
mortagage_debt = 0;
otherc_debt = 0;
previus_credit = 'NO';
discounts = '';
notes = '';
caja_ahorro = '';

ngOnInit() {
  this.credit_id = this.routee.snapshot.paramMap.get('id');

Swal2.showLoading();
// TRAE DATOS NECESARIOS DEL CREDITO PARA HACER EL ANALISIS / TRAE DATOS DEL BURO QUE SE NECESITAN
// TRAE CUSTOMER CREDIT CON EL CREDIT_ID
this.creditosService.traer_customer(this.credit_id).subscribe(customer => {
    this.cus = customer[0];
    this.creditosService.tiene_analisis(this.cus).subscribe(analysis => {
      this.analysisStatus = analysis.status;
      this.credit_analysis = analysis.credit_analysis;
      if (this.analysisStatus){
        this.credit_analysis = analysis.credit_analysis;
        this.analisis_id = this.credit_analysis[0]['id']
        this.car_credit = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['car_credit']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.departamental_credit = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['departamental_credit']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        // var discountsn =  Math.round(parseInt(this.credit_analysis[0]['discounts'])* 100)/100;
        // this.discounts =  discountsn.toString() + "%";
        this.discounts =  this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['discounts']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})) + "%";
        this.mortagage_loan = this.credit_analysis[0]['mortagage_loan'];
        this.notes = this.credit_analysis[0]['notes'];
        this.other_credits = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['other_credits']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.debt_cp = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['debt_cp']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.departamentalc_debt = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['departamentalc_debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.personalc_debt = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['personalc_debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.car_debt = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['car_debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.mortagage_debt = this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['mortagage_debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.otherc_debt =    this.formatNumber(this.esCero(parseFloat(this.credit_analysis[0]['otherc_debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));

        this.credit_cp = this.formatNumber(this.esCero(parseFloat(this.cus.creditos_cp).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.credit_lp = this.formatNumber(this.esCero(parseFloat(this.cus.creditos_lp).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));

      }else {
        // document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");
      }
    })
    this.creditosService.tiene_buro(this.cus).subscribe(buro => {
      this.buroStatus = buro.status;
      if (this.buroStatus){
        this.buro = buro.credit_bureau;
        this.reporte_buro_pdf = this.buro['extra1']
        if (this.reporte_buro_pdf != null){
          this.buroPdfStatus = true
        }
        let fechaReporte: any = document.getElementById('fechaReporte');
        let scoreBC: any = document.getElementById('scoreBuro');
        let scoreBC2: any = document.getElementById('scoreBuro2');
        let claveMop: any = document.getElementById('mop'); 
        let ultimaClave: any = document.getElementById('ultimaClave'); 
        let claveBaja: any = document.getElementById('claveBaja');
        let saldosVencidos: any = document.getElementById('saldosVencidos');
        if(!this.buro['bureau_info']){
          this.d1 = moment(this.buro['bureau_report']['respuesta']['persona']['hawkAlertConsulta'][0]['fechaReporte'],"D/M/yyyy");
        }else{
          this.d = new Date(this.buro['bureau_report']['results'][0]['updatedAt']);
          this.d.setMinutes( this.d.getMinutes() + this.d.getTimezoneOffset() );
          let month = '' + (this.d.getMonth() + 1);
          let day = '' + this.d.getDate();
          const year = this.d.getFullYear();
        
          if (month.length < 2) {
            month = '0' + month;
          }
          if (day.length < 2) {
            day = '0' + day;
          }
          this.d1 = [day, month, year].join('-');
        }
        fechaReporte.value  = this.d1;
        claveMop.value = 'NO'
        ultimaClave.value = '1'
        claveBaja.value = '1'
        if(!this.buro['bureau_info']){
          saldosVencidos.value = this.buro['bureau_report']['respuesta']['persona']['resumenReporte'][0]['totalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['respuesta']['persona']['scoreBuroCredito'][0]['valorScore']
        }else{
          saldosVencidos.value = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ResumenReporte']['ResumenReporte'][0]['TotalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ScoreBuroCredito']['ScoreBC'][0]['ValorScore']
        }

        if (this.calificacionBC <= 550 ){
          scoreBC.value = 'BAJA'  + ' (' + this.calificacionBC +')';
          scoreBC2.value = 'BAJA'  + ' (' + this.calificacionBC +')';
        }else if (this.calificacionBC  > 550 && this.calificacionBC  < 649 ){
          scoreBC.value = 'REGULAR' + ' (' + this.calificacionBC +')';
          scoreBC2.value = 'REGULAR' + ' (' + this.calificacionBC +')';
        } else if(this.calificacionBC > 649 && this.calificacionBC  < 749){
          scoreBC.value = 'BUENA'  + ' (' + this.calificacionBC +')';
          scoreBC2.value = 'BUENA'  + ' (' + this.calificacionBC +')';
        } else if (this.calificacionBC  > 749){
          scoreBC.value = 'EXCELENTE'  + ' (' + this.calificacionBC +')';
          scoreBC2.value = 'EXCELENTE'  + ' (' + this.calificacionBC +')';
        }
      }else {
        document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");
        document.getElementById('guardarAnalisisBtn').setAttribute("hidden", "hidden");
      }
    })
  })
  Swal2.close();
}

confirmarConsultaBuro(){

this.contribuyentesService.getPersonaFisica(this.cus.id_persona_fisica).subscribe(resp => {
  var person = resp[0];
  Swal.fire({
    title: '¿Está seguro?',
    html: `Por favor, verifique que el nombre del cliente sea correcto: <br>
            Apellido paterno: ${person.last_name} <br>
            Apellido materno: ${person.second_last_name} <br>
            Nombre: ${person.first_name}`,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Es correcto',
    cancelButtonText: 'Hay un error'
  }).then((result) => {
    if (result.isConfirmed) {
      this.pedirAutorizacionBuro();
    }else{
      // HAY QUE VER QUE HACER PARA MANDARLOS CORREGIR EL NOMBRE
      const formValues = Swal.fire({
        title: "Corrección de nombre",
        html: `
          <input id="apellidoP" class="swal2-input" placeholder='Apellido paterno'>
          <input id="apellidoM" class="swal2-input" placeholder='Apellido materno'>
          <input id="nombre" class="swal2-input" placeholder='Nombre'>
        `,
        focusConfirm: false,
        preConfirm: () => {
          /* return [
            (document.getElementById("swal-input1") as HTMLInputElement).value,
            (document.getElementById("swal-input2") as HTMLInputElement).value
          ]; */
          var apellidoP = (document.getElementById("apellidoP") as HTMLInputElement).value;
          var apellidoM = (document.getElementById("apellidoM") as HTMLInputElement).value;
          var nombre = (document.getElementById("nombre") as HTMLInputElement).value;

          var person = {
            last_name: apellidoP,
            second_last_name: apellidoM,
            first_name: nombre
          }
          var params = {
            person: person
          }
          this.contribuyentesService.actualizarPersonaFisica(this.cus.id_persona_fisica, params).subscribe(resp => {
            this.pedirAutorizacionBuro();
          }, err => {
            console.log(err);
            swal2.fire('Atención', 'Algo salió mal', 'info');
          })
        }
      });
    }
  })
})


}

pedirAutorizacionBuro(){
  Swal.fire({
    title: '¿Está seguro?',
    text: "Está a punto de realizar una consulta al servicio de Buró de Crédito!" + ' ' + "Asegurese de contar con la autorización de consulta de historial del cliente.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, consultar historial de crédito!',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      // console.log('ya se pediria el buro')
      this.traerBuro();
    }
  })
}

traerBuro(){

  // deshabilitar boton de buro
  document.getElementById('buro_btn').setAttribute("disabled", "disabled");
  document.getElementById('buro_btn').setAttribute("hidden", "hidden");
  Swal2.showLoading();
  this.creditosService.trae_buro(this.cus).subscribe(buro => {
    this.buroStatus = buro.status;
    if (this.buroStatus){
      this.buro = buro.credit_bureau;
        Swal.fire({
          title: 'Consulta Exitosa',
          text: '',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
            window.location.reload();
        } );
    }else {
      this.status_de_Buro = buro.status_de_Buro;
      const mensaje = buro.message;
      if(mensaje == "El cliente cuenta con codigo especial de SCORE"){
          Swal.fire({
            icon: 'error',
            title: 'Cliente con codigo especial de SCORE',
            text: 'SCORE de Buró: ' + buro.SCORE + ' ' + this.codigoExclusion(buro.SCORE),
            footer: '<strong>Revise información del cliente </strong>',
            showConfirmButton: true,
          })
      }else if(mensaje == "El cliente no cuenta con registros en Buró de Crédito"){
        Swal.fire({
          title: 'Información no encontrada',
          text: buro.status_de_Buro + ' ' + buro.message,
          icon: 'error',
          showConfirmButton: true,
        })
      }else if(mensaje == "Credenciales de buró expiradas en Moffin"){
        Swal.fire({
          title: 'Contacte al administrador',
          text: buro.message ,
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: '¿Eliminar registro?',
          cancelButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            this.eliminaBuro(buro.id)
          }else{
            window.location.reload();
          }
        })
      }
      else
      {
        Swal.fire({
          title: 'Error en Buró de Crédito',
          text: buro.message + this.status_de_Buro,
          icon: 'error',
          showConfirmButton: true,
        })
      }
    }
  })
}

eliminaBuro(id){
  this.creditosService.elimina_buro(id).subscribe(resp => {
    this.buroStatus = resp.status;
    if (this.buroStatus){
        Swal.fire({
          title: 'Registro de buró eliminado correctamente',
          text: '',
          icon: 'success',
        }). then ( res => {
            window.location.reload();
        } );
    }
  })
}

codigoExclusion(codigo){
  if(codigo == '-001'){
    return 'Consumidor Fallecido'
  }else if(codigo == '-005'){
    return 'Un archivo de crédito donde todas las cuentas están actualmente cerradas y por lo menos una cuenta con más de 90 días o más de morosidad. MOP>=04.'
  }else if(codigo == '-006'){
    return 'Un archivo de crédito donde todas las cuentas han sido abiertas en los últimos 6 meses y por lo menos una cuenta con más de 60 días o más de morosidad MOP>=03.'
  }else if(codigo == '-007'){
    return 'Un archivo de crédito donde todas las cuentas han sido abiertas en los últimos 6 meses y por lo menos una cuenta tiene 30 días o más de morosidad. MOP>=02.'
  }else if(codigo == '-008'){
    return 'El archivo de crédito no tiene por lo menos una cuenta actualizada en los últimos 12 meses o por lo menos una cuenta abierta en los últimos 6 meses ("Criterios mínimos de calificación").'
  }else if(codigo == '-009'){
    return 'Expediente sin cuentas para cálculo del Score Buró Histórico'
  }
}

 async generar_reporte_pdf_buro_credito(){
  try{
  Swal2.showLoading();
  this.r = await (await this.creditosService.genera_reporte_pdf_de_buro(this.cus)).toPromise();
  // buro => {
    // console.log("ANTES DEL IF")
    const reporteburoStatus = this.r.message;
    if (reporteburoStatus == 'ok'){
      // console.log("DENTRO DEL IF")

        Swal.fire({
          title: 'Se ha generado el reporte',
          text: '',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
          // if ( res.value ) {
            // console.log("respuesta")
            // console.log(res)
            window.location.reload();
            // this.router.navigate(['/panelcontrol/opciones']);
          // }
    
        } );
    }else {
      // console.log("ELSE")
      // document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");
      // document.getElementById('guardarAnalisisBtn').setAttribute("hidden", "hidden");

    }
    
  // }
    
  
} catch (error) {
// console.log("EL ERROR")
// console.log(error)
Swal.fire({
  title: 'Se ha generado el reporte',
  text: '',
  icon: 'success',
  showConfirmButton: true,
  showCancelButton: false,
  allowOutsideClick: false
}). then ( res => {
  // if ( res.value ) {
    // console.log("respuesta")
    // console.log(res)
    window.location.reload();
    // this.router.navigate(['/panelcontrol/opciones']);
  // }

} );
}
window.location.reload();

}

crearCreditAnalisis(){
  // crear_credit_analisis
  Swal2.showLoading();
  // console.log("DENTRO DE CREAR ANALISIS1")
  const valormortagage_loan = (<HTMLInputElement>document.getElementById('mortagage_loan')).value;
  const valorother_credits = (<HTMLInputElement>document.getElementById('other_credits')).value;
  const valorcar_credit = (<HTMLInputElement>document.getElementById('car_credit')).value;
  const valordebt_cp = (<HTMLInputElement>document.getElementById('debt_cp')).value;
  const valordepartamentalc_debt = (<HTMLInputElement>document.getElementById('departamentalc_debt')).value;
  const valorpersonalc_debt = (<HTMLInputElement>document.getElementById('personalc_debt')).value;
  const valorcar_debt = (<HTMLInputElement>document.getElementById('car_debt')).value;
  const valormortagage_debt = (<HTMLInputElement>document.getElementById('mortagage_debt')).value;
  const valornotes = (<HTMLInputElement>document.getElementById('notes')).value;
  const valorotherc_debt = (<HTMLInputElement>document.getElementById('otherc_debt')).value;
  // const valorprevius_credit = (<HTMLInputElement>document.getElementById('previus_credit')).value;
  const valorprevius_credit = '';
  // const valordiscounts = (<HTMLInputElement>document.getElementById('discounts')).value;
  const valordepartamental_credit = (<HTMLInputElement>document.getElementById('departamental_credit')).value;
  const valorcredit_lp = (<HTMLInputElement>document.getElementById('credit_lp')).value;
  const valorcredit_cp = (<HTMLInputElement>document.getElementById('credit_cp')).value;
  const valorfechaReporte = (<HTMLInputElement>document.getElementById('fechaReporte')).value;
  const valormop = (<HTMLInputElement>document.getElementById('mop')).value;
  const valorultimaClave = (<HTMLInputElement>document.getElementById('ultimaClave')).value;
  const valorclaveBaja = (<HTMLInputElement>document.getElementById('claveBaja')).value;
  const valorscoreBuro2 = (<HTMLInputElement>document.getElementById('scoreBuro2')).value;
  const customer_credit_id = this.cus['id_credito']

  //  MANDA SOLO LOS CAMPOS NECESARIOS
  this.creditosService.crear_credit_analisis(
    valorcredit_cp,
    valordepartamental_credit,
    valorcredit_lp,
    valorcar_credit,
    valormortagage_loan,
    valorother_credits,
    valordebt_cp,
    valordepartamentalc_debt,
    valorpersonalc_debt,
    valorcar_debt,
    valormortagage_debt,
    valornotes,
    valorotherc_debt,
    valorprevius_credit,
    // valordiscounts,
    valormop,
    valorscoreBuro2,
    valorultimaClave,
    customer_credit_id,
    valorfechaReporte,
    valorclaveBaja
    ).subscribe( () => {
    Swal.fire({
      title: 'Creacion de Analisis exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        // console.log("respuesta")
        // console.log(res)
        window.location.reload();
        // this.router.navigate(['/panelcontrol/opciones']);
      }
    } );
  }, (err) => {             console.log(err);
                            Swal.fire(
                              'Error al crear Analisis',
                              'Error',
                              'error'
                           );
                        } ); 
}

actualizaAnalisis(){
  var credit_analysis = {};
  credit_analysis['notes'] = (<HTMLInputElement>document.getElementById('notes')).value;
  var params = {}
  params['credit_analysis'] = credit_analysis;
  this.creditosService.actualizaAnalisis(this.analisis_id, params).subscribe(resp => {
    swal2.fire('Éxito', 'Actualizado correctamente', 'success');
  }, err => {
    console.log(err);
    swal2.fire('Atención', 'Algo salió mal', 'info');
  })
}

actualizarCreditAnalisis(){
  // crear_credit_analisis
  Swal2.showLoading();
  // console.log("DENTRO DE ACTIALIZAR actualizarCreditAnalisis")
  const valormortagage_loan = (<HTMLInputElement>document.getElementById('mortagage_loan')).value;
  const valorother_credits = (<HTMLInputElement>document.getElementById('other_credits')).value;
  const valorcar_credit = (<HTMLInputElement>document.getElementById('car_credit')).value;
  const valordepartamental_credit = (<HTMLInputElement>document.getElementById('departamental_credit')).value;
  const valorcredit_lp = (<HTMLInputElement>document.getElementById('credit_lp')).value;
  const valorcredit_cp = (<HTMLInputElement>document.getElementById('credit_cp')).value;
  const valordebt_cp = (<HTMLInputElement>document.getElementById('debt_cp')).value;
  const valordepartamentalc_debt = (<HTMLInputElement>document.getElementById('departamentalc_debt')).value;
  const valorpersonalc_debt = (<HTMLInputElement>document.getElementById('personalc_debt')).value;
  const valorcar_debt = (<HTMLInputElement>document.getElementById('car_debt')).value;
  const valormortagage_debt = (<HTMLInputElement>document.getElementById('mortagage_debt')).value;
  const valornotes = (<HTMLInputElement>document.getElementById('notes')).value;
  const valorotherc_debt = (<HTMLInputElement>document.getElementById('otherc_debt')).value;
  const valorprevius_credit = (<HTMLInputElement>document.getElementById('previus_credit')).value;
  // const valordiscounts = (<HTMLInputElement>document.getElementById('discounts')).value;
  //  MANDA TODOS LOS DATOS RELEVANTES DE LA TABLA CREDIT ANALISIS
  //  METODO QUE ACTUALICE EL ANALISIS
  this.creditosService.actualizar_credit_analisis(
    this.analisis_id,
    valormortagage_loan,
    valorother_credits,
    valorcar_credit,
    valordepartamental_credit,
    valorcredit_lp,
    valorcredit_cp,
    valordebt_cp,
    valordepartamentalc_debt,
    valorpersonalc_debt,
    valorcar_debt,
    valormortagage_debt,
    valorotherc_debt,
    valorprevius_credit,
    // valordiscounts,
    ).subscribe( () => {
    Swal.fire({
      title: 'Analisis Actualizado',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        // console.log("respuesta")
        // console.log(res)
        // this.router.navigate(['/panelcontrol/opciones']);
      }
    } );
  }, (err) => {             console.log(err);
                            Swal.fire(
                              'Error al crear Analisis',
                              'Error',
                              'error'
                           );
                        } ); 
}

esCero(valor){
  if(!valor){
    return '0';
  }
  else {
    return valor;
  }
}

formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

}
