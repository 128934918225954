import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import swal2 from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { CreditosService } from '../../services/creditos/creditos.service';
import { ListasService } from '../../services/listas/listas.service';
import { AwsService } from '../../services/awsser/aws.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
declare var $;
import * as fs from 'file-saver';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-aprobar-creditos',
  templateUrl: './aprobar-creditos.component.html',
  styleUrls: ['./aprobar-creditos.component.scss']
})
export class AprobarCreditosComponent implements OnInit {
  uploadForm: FormGroup;
  fileName = 'Layout.xlsx';
  facturas: any[] = [];
  url: any;
   calificacionBC:any;
  file: any;
  datosdetalles: any[] = [];
  selectedSol: any[] = [];
  cols: any[] = [];
  solicitudes: any[] = [];
  estatussolicitudes: any[] = [];
  usuarios: any[] = [];
  idu: string;
  documentonulo: boolean;
  uploadURL: Observable<string>;
  uploadProgress: Observable<number>;
  showModal: boolean = false;
  showmodalds: boolean;
  idsolicitud: string;
  foliosolicitud: string;
  carpeta: string;
  nombrearchivosubido: string;
  estatus1: string;
  estatusburo: string;
  estatusindice: string;
  estatuspayment: string;
  estatusflow: string;
  notes = '';

  calificaciones = [];
  requests = [];
  terms = [];
  paymentPeriods = [];
  intereses = 16;
  colDetallesCustomer = [];
  idRechazo = '';
  ngSelect = '';
  urlburo = '';

  creditos = [];
  rates = [];
  ivaRates = [];
  anuality_date = [];
  payments = [];
  documents = [];
  regimen_fiscal = [];
  regimen_fiscal_contribuyente = [];
  client_status = [];
  filtro = 'SO';
  credit_status = [];
  datosEmpleado = {};
  resumen_creditos = [];
  creditos_buro = [];
  buro: string;
  analysis: string;
  buroStatus = false;
  analysisStatus = false;
  stepBuro = false;
  rfcBC = '';
  scoreBC = '';
  fechaReporte = '';
  saldosVencidos = '';
  accured_liabilities = '';
  anual_rate = '';
  balance_due = '';
  car_credit = '';
  car_creditnum = 0;
  cash_flow = 'Dato no capturado';
  created_at = '';
  analisis_credit_status = '';
  credit_type = '';
  customer_credit_id = '';
  customer_number = '';
  debt = '';
  debtnum = 0;
  debt_horizon = 'Dato no capturado'; 
  debt_rate = 'Dato no capturado';
  departamental_credit = '';
  discounts = 'Dato no capturado';
  extra_incomes = '';
  extra_deductions = '';
  id = '';
  last_key = '';
  lowest_key = '';
  mop_key = '';
  mortagage_loan = 'Dato no capturado';
  mortagage_loannum = 0;
  net_flow = 'Dato no capturado';
  net_flownum = 0;
  other_credits = '';
  overall_rate = '';
  payment_capacity = '';
  previus_credit = '';
  report_date = '';
  total_amount = '';
  total_cost = '';
  total_debt = '';
  ingresos_netos = "";
  ingresos_netosnum = 0;
  egresos_netos = '';
  egresos_netosnum = 0;
  gastos_familiares = '';
  gastos_familiaresnum = 0;
  renta_casa = '';
  renta_casanum = 0;
  creditos_CP = '';
  creditos_CPnum = 0;
  creditos_LP = '';
  creditos_LPnum = 0;
  periodo_pago = '';
  pdf:any;
  creditoSeleccionadoTabla = {};
  maritalStatus: any;
  lists = [];
  regimen_fiscal2 = [];
  cus = {};
  detail_extra_income = [];
  detail_extra_deductions = [];
  garantia = {
    guarantee_object: '',
    guarantee_object_address: '',
    guarantee_email: '',
    guarantee_extra: '',
  }
  garantiaModal = false;
  
  constructor(private _formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              private creditosService: CreditosService,
              private formBuilder: FormBuilder, private httpClient: HttpClient,
              private listasService: ListasService,
              private awsService: AwsService,
              private reportesService: ReportesService ,
              private sanitizer: DomSanitizer
              ) {}

  ngOnInit() {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    this.creditos = [];

    this.listasService.getLists().subscribe(resp => {
      resp['data'].forEach(list => {
        if(list['attributes'].domain === 'ANUALITY_DATE'){
          this.anuality_date.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'PERSON_FISCAL_REGIME'){
          this.regimen_fiscal.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'CONTRIBUTOR_FISCAL_REGIME'){
          this.regimen_fiscal_contribuyente.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'CLIENT_STATUS'){
          this.client_status.push(list['attributes']);
        }
        else if(list['attributes'].domain === 'CREDIT_STATUS'){
          this.credit_status.push(list['attributes']);
        }
      });
    })

    this.creditosService.ext_rates().subscribe(resp => {
      resp['data'].forEach(rate => {
        if(rate.attributes.rate_type === 'TA'){
          this.rates.push(rate.attributes);
        }else if(rate.attributes.rate_type === 'IVA'){
          this.ivaRates.push(rate.attributes);
        }
      });
    })

    this.creditosService.getCondiciones()
      .subscribe(resp => {
        this.terms = resp;
        this.creditosService.getperiodosPagos()
          .subscribe(resp => {
            this.paymentPeriods = resp;
            this.paymentPeriods.push({id:'0', key:'Pago Fijo'});
            this.creditosService.getCredits().subscribe(credits => {
              this.creditos = [];
              Object.keys(credits['data']).map(key => {
                let credit = credits['data'][key].attributes;
                if(this.filtro == 'SO'){
                  if(credit['status'] === 'SO'){
                    this.armarCreditos(credit);
                  }
                }else if(this.filtro == 'PA'){
                  if(credit['status'] === 'PA'){
                    this.armarCreditos(credit)
                  }
                }else if(this.filtro == 'PR'){
                  if(credit['status'] === 'PR'){
                    this.armarCreditos(credit)
                  }
                }else if(this.filtro == 'RZ'){
                  if(credit['status'] === 'RZ'){
                    this.armarCreditos(credit)
                  }
                }
              })
            })
          })
        })

    swal2.close();

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    const estatus: any = document.getElementById('estatus');

    this.selectedSol = [];

    this.idu = localStorage.getItem('uid'); 

    this.cols = [

      { field: 'credit_folio', header: 'Folio', moneda: false },
      { field: 'created_at', header: 'Fecha solicitud', moneda: false },
      { field: 'start_date', header: 'Fecha Inicio', moneda: false },
      { field: 'nombre_empleado', header: 'Nombre Empleado', moneda: false },
      { field: 'compañia', header: 'Compañía', moneda: false },
      { field: 'interests', header: 'Intereses', moneda: true },
      { field: 'commission1', header: 'Comisión', moneda: true },
      { field: 'insurance1', header: 'Seguro', moneda: true },
      { field: 'rate', header: 'Tasa', moneda: false },
      { field: 'total_debt', header: 'Adeudo Total', moneda: true },
      { field: 'total_requested', header: 'Pedido Total', moneda: true },
      { field: 'fixed_payment', header: 'Pago Fijo', moneda: true },
      { field: 'term', header: 'Plazo', moneda: false },
      { field: 'payment_period', header: 'Periodicidad', moneda: false },
      { field: 'has_guarantee_object', header: 'Grarantía', moneda: false },

    ];

    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
    this.maritalStatus = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
    this.regimen_fiscal2 = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
    })
  }

  detalleGarantia(rowData){
    Object.keys(this.garantia).forEach(k => {
      this.garantia[k] = rowData[k];
    })
    console.log(this.garantia)
    this.garantiaModal = true;
  }

  cerrarGarantia(){
    Object.keys(this.garantia).forEach(k => {
      this.garantia[k] = '';
    })
    this.garantiaModal = false;
  }

  async detalleCliente(rowData) {
    let customer = rowData.customer;
    this.showmodalds = true;
    this.datosEmpleado = {};
    this.egresos_netos = rowData.customer.egresos_netos;
    if (!rowData.customer.gastos_familiares){
        this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat('2500').toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.gastos_familiaresnum = parseFloat('2500');
      } else if(rowData.customer.gastos_familiares == 0 ) {
        this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat(rowData.customer.gastos_familiares).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2})) + 2500;
        this.gastos_familiaresnum = parseFloat(rowData.customer.gastos_familiares) + 2500;
      } else{
      this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat(rowData.customer.gastos_familiares).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.gastos_familiaresnum = parseFloat(rowData.customer.gastos_familiares)
    }

    this.renta_casa = this.formatNumber(this.esNulo(parseFloat(rowData.customer.renta).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
    this.creditos_CP = this.formatNumber(this.esNulo(parseFloat(rowData.customer.creditos_cp).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
    
    this.renta_casanum = parseFloat(rowData.customer.renta)
    this.creditos_CPnum = parseFloat(rowData.customer.creditos_cp)
    this.creditos_LPnum = parseFloat(rowData.customer.creditos_lp)
    this.periodo_pago = this.formatNumber(this.esNulo(parseFloat(rowData.customer.periodo_pago).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
    // this.datosdetalles = [];
    // this.datosdetalles.push(rowData.customer);
    
    this.creditosService.tiene_analisis(rowData.customer).subscribe(analysis => {
      this.analysisStatus = analysis.status;
      if (this.analysisStatus){
        this.analysis = analysis.credit_analysis;
        this.accured_liabilities = this.analysis[0]['accured_liabilities']
        this.debt_horizon = this.analysis[0]['debt_horizon']
        this.anual_rate = this.analysis[0]['anual_rate'];
        this.balance_due = this.analysis[0]['balance_due'];
        this.car_credit = this.analysis[0]['car_credit'];
        this.notes = this.analysis[0]['notes'];
        this.car_creditnum = parseFloat(this.analysis[0]['car_credit']);
        this.credit_status = this.analysis[0]['credit_status'];
        this.cash_flow = this.analysis[0]['cash_flow'];
        if(parseFloat(this.cash_flow) >= 80 && parseFloat(this.cash_flow) <= 100 ){
          this.estatusflow = "check"
        }else if(parseFloat(this.cash_flow) >= 101 && parseFloat(this.cash_flow) <= 110){
          this.estatusflow = "priority_high"
        }else{
          this.estatusflow = "close"
        }
        this.created_at = this.analysis[0]['created_at'];
        this.analisis_credit_status = this.analysis[0]['credit_status'];
        this.credit_type = this.analysis[0]['credit_type'];
        this.customer_credit_id = this.analysis[0]['customer_credit_id'];
        this.customer_number = this.analysis[0]['customer_number'];
        this.debt = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.debtnum = parseFloat(this.analysis[0]['debt'])
        this.debt_rate = this.analysis[0]['debt_rate'];
        if(parseFloat(this.debt_rate) <= 49){
          this.estatusindice = "check"
        }else if(parseFloat(this.debt_rate) >= 50 && parseFloat(this.debt_rate) <= 65){
          this.estatusindice = "priority_high"
        }else{
          this.estatusindice = "close"
        }
        this.departamental_credit = this.analysis[0]['departamental_credit'];
        // this.discounts = this.analysis[0]['discounts'];
        // var discountsn =  Math.round(parseInt(this.analysis[0]['discounts'])* 100)/100;
        // this.discounts =  discountsn.toString() + "%";
        this.discounts =  (parseInt(this.analysis[0]['discounts']).toFixed(2)).toString() + "%";
        this.id = this.analysis[0]['id'];
        this.last_key = this.analysis[0]['last_key'];
        this.lowest_key = this.analysis[0]['lowest_key'];
        this.mop_key = this.analysis[0]['mop_key'];
        this.mortagage_loan = this.analysis[0]['mortagage_loan'];
        this.mortagage_loannum = parseFloat(this.analysis[0]['mortagage_loan'])
        this.net_flow = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['net_flow']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.net_flownum = parseFloat(this.analysis[0]['net_flow'])
        this.other_credits = this.analysis[0]['other_credits'];
        this.overall_rate = this.analysis[0]['overall_rate'];
        this.payment_capacity = this.analysis[0]['payment_capacity'];
        if(parseFloat(this.payment_capacity) <= 50){
          this.estatuspayment = "check"
        }else if(parseFloat(this.payment_capacity) >= 51 && parseFloat(this.payment_capacity) >= 65){
          this.estatuspayment = "priority_high"
        }else{
          this.estatuspayment = "close"

        }


        this.previus_credit = this.analysis[0]['previus_credit'];
        this.report_date = this.analysis[0]['report_date'];
        this.total_amount = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['total_amount']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.total_cost = this.analysis[0]['total_cost'];
        this.total_debt = this.analysis[0]['total_debt'];
        this.egresos_netos = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['monthly_expenses']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.egresos_netosnum = parseFloat(this.analysis[0]['monthly_expenses'])
        this.creditos_LPnum = this.creditos_LPnum + this.mortagage_loannum + this.car_creditnum
        if(parseFloat(this.discounts) > 30){
          this.estatus1 = "close"
        }else{
          this.estatus1 = "check"
        }
        this.creditos_LP = this.formatNumber(this.esNulo(this.creditos_LPnum.toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      }else {
        console.log("NO TIENE ANALISIS")
      }

    })

    this.creditosService.tiene_buro(rowData.customer).subscribe(buro => {
      this.buroStatus = buro.status;
      if (this.buroStatus){
        this.buro = buro.credit_bureau;
        // console.log("BURO")
        // console.log(this.buro['bureau_report']['respuesta']['persona']['nombre']['rfc'])
        if(!this.buro['bureau_info']){
          this.fechaReporte = this.buro['bureau_report']['respuesta']['persona']['hawkAlertConsulta'][0]['fechaReporte'];
          this.rfcBC = this.buro['bureau_report']['respuesta']['persona']['nombre']['rfc'];
          this.saldosVencidos = this.buro['bureau_report']['respuesta']['persona']['resumenReporte'][0]['totalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['respuesta']['persona']['scoreBuroCredito'][0]['valorScore']
          console.log("this.buro['bureau_report']['respuesta']['persona']['cuentas']")
          console.log(this.buro['bureau_report']['respuesta']['persona']['cuentas'])
          this.resumen_creditos = this.buro['bureau_report']['respuesta']['persona']['cuentas']
        }else{
          this.fechaReporte = this.buro['bureau_report']['results'][0]['updatedAt'];
          this.rfcBC = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Nombre']['RFC'];
          this.saldosVencidos = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ResumenReporte']['ResumenReporte'][0]['TotalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ScoreBuroCredito']['ScoreBC'][0]['ValorScore']
          this.resumen_creditos = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta']
        }

        this.pdf = this.buro['extra1']
        this.urlburo = `https://docs.google.com/viewer?url=${this.pdf}&embedded=true`

        if(this.calificacionBC <= 550){
          this.estatusburo = 'close'
          // this.estatusburo = 'priority_high'
        }else if(this.calificacionBC >= 551 && this.calificacionBC <=650){
          this.estatusburo = 'priority_high'
        }else{
          this.estatusburo = 'check'
        }
        this.creditos_buro = this.get_ur_credits(this.resumen_creditos)
      }else {
        // stepBuro
        console.log("NO TIENE BURO")
        // document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");

      }

    });
    
    this.reportesService.getRegistrationInfo(rowData.id).subscribe(info => {
      this.datosEmpleado = info[0];
      this.extra_incomes = this.datosEmpleado['extra_income'];
      this.extra_deductions = this.datosEmpleado['extra_deductions'];
      this.detail_extra_income = JSON.parse(this.datosEmpleado['detail_extra_income']);
      this.detail_extra_deductions = JSON.parse(this.datosEmpleado['detail_extra_deductions']);
      this.datosEmpleado['destino'] = customer.destino; console.log(this.datosEmpleado)
      this.datosEmpleado['dependientes'] = parseInt(this.datosEmpleado['senior_dependents']) + parseInt(this.datosEmpleado['minior_dependents'])
      this.datosEmpleado['martial_status_value'] = this.maritalStatus.find(m => m.key == this.datosEmpleado['martial_status']).value;
      this.datosEmpleado['fiscal_regime_value'] = this.regimen_fiscal2.find(r => r.key == this.datosEmpleado['fiscal_regime']).value;
      this.datosEmpleado['business_name'] = rowData.customer.business_name;
      if(this.datosEmpleado['salary_period'] == 'Quincenal'){
        this.datosEmpleado['month_salary'] = ((parseFloat(this.esNulo(this.datosEmpleado['salary'])) + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers'])) ) * 2).toFixed(2);
      }else if(this.datosEmpleado['salary_period'] == 'Semanal'){
        this.datosEmpleado['month_salary'] = ((parseFloat(this.esNulo(this.datosEmpleado['salary'])) + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers'])) ) * 4.33).toFixed(2);
      }
      let ingresos_mensuales_y_otros_ingresos = parseFloat(this.datosEmpleado['month_salary']) + parseFloat(this.datosEmpleado['other_income']);
      this.ingresos_netos = this.formatNumber(this.esNulo(ingresos_mensuales_y_otros_ingresos.toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.ingresos_netosnum = ingresos_mensuales_y_otros_ingresos;
      this.awsService.getUserDocuments(rowData.customer.id_contribuyente).subscribe(docs => {
        this.documents = [];
        docs['data'].forEach(doc => {
          if(doc.attributes.name == "Comprobantes de ingresos del empleado"){
            var arrayUrl = doc.attributes.url.split('*');
            arrayUrl.forEach(url => {
              if(url != ''){
                let params = {
                  contributor_id: doc.attributes.contributor_id,
                  created_at: doc.attributes.created_at,
                  extra1: null,
                  extra2: null,
                  extra3: null,
                  file_type_document_id: doc.attributes.file_type_document_id,
                  id: doc.attributes.id,
                  name: doc.attributes.name,
                  notes: null,
                  status: doc.attributes.status,
                  updated_at: doc.attributes.updated_at,
                  url: url
                }
                let variable = doc.attributes;
                variable.url = url;
                this.documents.push(params);
              }
            });
          }else{
            this.documents.push(doc.attributes);
          }
          /* if(doc.attributes.name != "Comprobantes de ingresos del empleado"){
            if(doc.attributes.url){
  
              this.documents.push(doc.attributes);
            }
          }else{
            var arrayUrl = doc.attributes.url.split('*');
            arrayUrl.forEach(url => {
              if(url != ''){
                doc.attributes.url = url;
                this.documents.push(doc.attributes);
              }
            });
          } */
        });
        if(this.buroStatus){
          if(this.buro['extra1'] != null){
            this.documents.push({'url': this.buro['extra1'], 'name': 'Reporte Buró de Crédito'});
          }
        }
          if(this.datosEmpleado['extra1'] !== null){
            this.documents.push({'url': this.datosEmpleado['extra1'], 'name': 'Expediente'});
          }        
      })
    });

  }

  onStepBuro(){
    this.stepBuro = false;
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  armarCreditos(credit){
    let status = this.credit_status.find(s => s.key == credit.status);
    credit.status = status.value;
    this.creditosService.traer_customer(credit.id).subscribe(customer => {
      let cus = customer[0];
      credit.nombre_empleado = cus.nombre_cliente;
      if(cus.pf_regimen_fiscal != null){
        let rf = this.regimen_fiscal.find(r => cus.pf_regimen_fiscal == r.key);
        cus.pf_regimen_fiscal = rf.value;
      }else if(cus.pm_regimen_fiscal != null){
        let rf = this.regimen_fiscal.find(r => cus.pm_regimen_fiscal == r.key);
        cus.pm_regimen_fiscal = rf.value;
      }
      let tc = this.regimen_fiscal_contribuyente.find(c => cus.tipo_contribuyente == c.key);
      cus.tc = cus.tipo_contribuyente;
      cus.tipo_contribuyente = tc.value;
      let cs = this.client_status.find(s => cus.status_cliente == s.key);
      cus.status_cliente = cs.value;
      credit.customer = cus;
      let pp = this.paymentPeriods.find(p => p.id === credit.payment_period_id);
      credit.payment_period = pp.key;
      let terms = this.terms.filter(t => t.term_type === cus.salary_period);
      terms.push({id:'0', key:'Pago Fijo'});
      credit.terms = terms;
      if(credit.term_id == null){
        credit.term_id = "0";
        credit.term = 'Pago fijo';
      }else{
        let term = terms.find(t => t.id == credit.term_id);
        if(term != undefined){
          credit.term = term.key;
        }else{
          credit.term_id = "0";
          credit.term = 'Pago fijo';
        }
      }
      this.creditos.push(credit);
    })
  }

  cambiarFiltro(status){
    this.filtro = status;
    setTimeout(() => {
      document.getElementById('reset').click();
    }, 1800);
    this.ngOnInit();
  }

  guardar(rowData){
    console.log(rowData); return
    localStorage.setItem('customer_credit', rowData.id);
  }

  rechazar(id,nota){
    this.creditosService.updateCredit(id, {status: 'RZ', rejection_note: nota}).subscribe(resp => {
      swal2.fire({
        title: 'Exito', 
        text: 'Crédito rechazado', 
        icon: 'success',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        this.ngOnInit();
      });
    }, err => {
      console.log(err);
      swal2.fire('Error', 'Algo salió mal', 'info');
    })
  }

  confirmarRechazo(id,nombre){
    swal2.fire({
      title: 'Está a punto de rechazar la solicitud de ' + nombre ,
      text: "Motivo de rechazo",
      input: 'text',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, rechazar crédito',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      inputPlaceholder: "Introduzca el motivo de rechazo",
      inputValidator: (value) => {
        if (!value) {
          return 'Favor de ingresar un motivo!'
        }
      }      
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value === "") {
          swal2.fire("Precaucion", "Favor de agregar un motivo de rechazo", "warning");
          return false
        }
        this.rechazar(id,result.value)
      }
    })
  }

  crearProject(info){
    swal2.showLoading()
    this.creditosService.updateCredit(info.id, {status: 'RE'}).subscribe(resp => {
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      let start_date;

      if(month < 10){
        start_date =  `${year}-0${month}-${day}`;
      }else{
        start_date = `${year}-${month}-${day}`;
      }
      const params = {};
      const customer_credit = {
        total_requested: info.total_requested,
        status: 'PR',
        start_date: start_date,
        customer_id: info.customer_id,
        rate: info.rate,
        user_id: localStorage.getItem('uid'),
        iva_percent: info.iva_percent,
        currency: info.currency
        // term_id: info.term_id,
        // payment_period_id: info.payment_period_id,
      }
      if(info.term_id === '0'){
        params['payment_amount'] = info.fixed_payment;
      }else {
        customer_credit['term_id'] = info.term_id;
        // params['payment_period_id'] = info.payment_period_id;
      }
      if(info.hasOwnProperty('anualidad')){
        customer_credit['anuality'] = info.anualidad;
        if(info.hasOwnProperty('anuality_date')){
          customer_credit['anuality_date'] = info.anuality_date;
        }else{
          swal2.fire('Faltan datos', 'Seleccione una fecha para el pago de la anualidad', 'info');
          return;
        }
      }
      params['customer_credit'] = customer_credit;
      // console.log(params);
      // SE ENVIAN LOS CORREOS AL COMITE Y EMPRESA PARA QUE FIRMEN/ACEPTEN EL CREDITO
      this.creditosService.customerCredit(params)
        .subscribe(resp => {
          swal2.fire({
            title: 'Operación correcta', 
            text: 'Propuesta enviada', 
            icon: 'success',
            allowOutsideClick: false,
            showConfirmButton: true
          }).then(resp => {
            if(resp.value){
              this.ngOnInit();

            }
          });
        }, err => {
          console.log('err', err);
          try{
            swal2.fire('Hubo un problema', err.error.errors[0], 'info');
          }catch{
            swal2.fire('', 'Hubo un problema', 'info');
          }
        })
    }, err => {
      console.log('error', err);
      swal2.fire('', 'Hubo un problema', 'info');
    })
  }

  //------------------------------------------------------------

  get_ur_credits(credits){ 
    var ur_credits = {mop: 'UR', cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0,
                saldo_vencido: 0, pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0,
                saldo_cerradas: 0, monto_cerradas: 0}

    var credits_01 = {mop: '01',cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0, saldo_vencido: 0,
    pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0, saldo_cerradas: 0,
    monto_cerradas: 0}


    var all_credits = []


    credits.forEach(credit =>{
      if(credit['fechaCierreCuenta'] && credit['formaPagoActual'] === 'UR'){
        ur_credits['cuentas_cerradas'] += 1
        ur_credits['limite_cerradas'] += parseInt(credit['limiteCredito'] ? credit['limiteCredito'] : 0)
        ur_credits['maximo_cerradas'] += parseInt(credit['creditoMaximo'])
        ur_credits['saldo_cerradas'] += parseInt(credit['saldoActual'])
        ur_credits['monto_cerradas'] += parseInt(credit['montoPagar'])
      }else if (credit['fechaCierreCuenta'] && credit['formaPagoActual'] === '01'){
        credits_01['cuentas_cerradas'] += 1
        credits_01['limite_cerradas'] += parseInt(credit['limiteCredito'] ? credit['limiteCredito'] : 0)
        credits_01['maximo_cerradas'] += parseInt(credit['creditoMaximo'])
        credits_01['saldo_cerradas'] += parseInt(credit['saldoActual'])
        credits_01['monto_cerradas'] += parseInt(credit['montoPagar'])
      }else if (!credit['fechaCierreCuenta'] && credit['formaPagoActual'] === 'UR'){
        ur_credits['cuentas_abiertas'] += 1
        ur_credits['limite_abiertas'] += parseInt(credit['limiteCredito'] ? credit['limiteCredito'] : 0)
        ur_credits['maximo_abiertas'] += parseInt(credit['creditoMaximo'])
        ur_credits['saldo_actual'] += parseInt(credit['saldoActual'])
        ur_credits['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        ur_credits['pago_realizar'] += parseInt(credit['montoPagar'])
      }else if (!credit['fechaCierreCuenta'] && credit['formaPagoActual'] === '01'){
        credits_01['cuentas_abiertas'] += 1
        credits_01['limite_abiertas'] += parseInt(credit['limiteCredito'] ? credit['limiteCredito'] : 0)
        credits_01['maximo_abiertas'] += parseInt(credit['creditoMaximo'])
        credits_01['saldo_actual'] += parseInt(credit['saldoActual'])
        credits_01['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        credits_01['pago_realizar'] += parseInt(credit['montoPagar'])
      }
    });

    all_credits.push(ur_credits)
    all_credits.push(credits_01)

    return all_credits
  }

  esNulo (dato){
    if(dato == null){
      return 0;
    }else{
      return dato;
    }
  }

  showModalf(rowData) {
    var id = rowData.id;
    this.creditoSeleccionadoTabla = rowData;
    this.payments = [];
    this.creditosService.customerCreditPayments(id).subscribe(pays => {
      pays['data'].forEach(p => {
/*         this.payments.push(p['attributes']); */
          const payment = p['attributes'];/* borrar */
          if(payment.commission == null ){
            payment.commission = 0
          }
          if(payment.insurance == null ){
            payment.insurance = 0
          }
/*           payment.insurance = 100; // Valor fijo de seguro
          payment.commission = 50; // Valor fijo de comisión */
          this.payments.push(payment);/* borrar */
      });
    });
    
    this.showModal = true; // Show-Hide Modal Check
  }

  hideModal() {
    this.showModal = false;
  }  

  show(datarow) {
    this.showModal = true; // Show-Hide Modal Check
    // console.log(datarow);
    this.idsolicitud = datarow.id;
    this.foliosolicitud = datarow.folio;
    // return '2';
  }

  hideds() {
    this.showmodalds = false;
  }  

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }


  exportexcel() {
    this.fileName = "Layout" + '.xlsx';
    const Excel = require('exceljs');
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('base');
    let titulo = ['PLANTILLA DE PAGOS PROGRAMADOS']
    worksheet.addRow(titulo);
    worksheet.mergeCells(1, 1, 1, 2);
    let pagos = ['No. De pagos', this.payments.length]
    worksheet.addRow(pagos);
    let header=['Fecha de pago', 'Capital'];
    worksheet.addRow(header);
    for (const prop in this.payments) {
      var datePipe = new DatePipe('en-US');
      var fecha = datePipe.transform(this.payments[prop].payment_date, 'dd/MM/yyyy');
      worksheet.addRow([
        // this.payments[prop].pay_number,
        // new Date(this.payments[prop].payment_date).toLocaleDateString('en-US'),
        fecha,
        this.payments[prop].capital
      ]);
    } 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
  });
  }

}
