import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService,  } from '../services/service.index';
import { DatosUsuario } from '../models/usuario.model';
import Swal2 from 'sweetalert2';
import { RolesService } from '../services/roles/roles.service';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  roloptions: any[] = [];
  recuperacont = false;
  constructor(public router: Router,
              private usuarioservice: UsuarioService,
              private rolesService: RolesService) { }

  ngOnInit() {

    if(localStorage.length > 0){
      this.rolesService.getRol(localStorage.getItem('rid')).subscribe(resp => {
        if(resp[0].name === 'SuperAdmin'){
          this.router.navigate(['/panelcontrol/aprobarCreditos']);
        }else if(resp[0].name === 'Clientes'){
          this.usuarioservice.getCustomerxidu(localStorage.getItem('uid')).subscribe(r => {
            if (r.length > 0) {
              this.router.navigate([`/maincliente/solicitudcredito`]).then( () => {
              //  window.location.reload();
              });
            } else {
              this.router.navigate([`/registroemp`]).then( () => { 
              //  window.location.reload() 
              } )
            }
          })
        }else{
          this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
        }
        // }else if(resp[0].name === 'Mesa de control'){
        //   this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
        // }else if(resp[0].name === 'Analista'){
        //   this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
        // }else if(resp[0].name === 'Comité'){
        //   this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
        // }else if(resp[0].name === 'Empresa'){
        //   this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
        // }else if(resp[0].name === 'Tesorería'){
        //   this.router.navigate(['/panelcontrol/aprobarCreditos/mesaControl']);
        // }
      }, err => {
        console.log('error', err);
        window.location.reload();
      });
    }
  }

  login() {
    Swal2.showLoading()
    const correo = (document.getElementById('correo') as HTMLInputElement).value;
    const pass = (document.getElementById('password') as HTMLInputElement).value;
    this.usuarioservice.login( correo, pass ).subscribe( async resp => {
      if ( resp.data.attributes.status !== 'AC' ) {
        //Swal2.close();
        Swal2.fire(
        'El usuario no se encuentra activo',
        '',
        'error'
     );
     this.ngOnInit();
      // } 
      // else if (resp.data.attributes.role_id === 'a4354b20-59ef-4f07-825f-5335e4cb02c9') {
      //     localStorage.setItem('uid', resp.data.attributes.id );
      //     localStorage.setItem('tkn', resp.data.token );
      //     localStorage.setItem('emailuser', resp.data.attributes.email );
      //     localStorage.setItem('expiratoken', resp.data.expires_at);
      //     localStorage.setItem('rid', resp.data.attributes.role_id);
      //     this.router.navigate([`/registroemp`]).then( () => { 
      //     window.location.reload() 
      //   } )
      } else {
        localStorage.setItem('uid', resp.data.attributes.id );
        localStorage.setItem('tkn', resp.data.token );
        localStorage.setItem('emailuser', resp.data.attributes.email );
        localStorage.setItem('nombreuser', resp.data.attributes.name );
        localStorage.setItem('expiratoken', resp.data.expires_at);
        localStorage.setItem('firmante', resp.data.attributes.company_signatory);
        if(resp.data.attributes.role_id != null){
          localStorage.setItem('rid', resp.data.attributes.role_id);
          this.ngOnInit();
        }else{
          swal2.fire('Datos incompletos', 'Comuníquese con el administrador', 'info');
        }
      // this.router.navigate([`/maincliente/solicitudcredito/${resp.data.attributes.id}`]).then( () => { 
      //                                               localStorage.setItem('uid', resp.data.attributes.id );
      //                                               localStorage.setItem('tkn', resp.data.token );
      //                                               localStorage.setItem('emailuser', resp.data.attributes.email );
      //                                               localStorage.setItem('expiratoken', resp.data.expires_at);
      //                                               localStorage.setItem('rid', resp.data.attributes.role_id);
      //                                               window.location.reload() 
      //                                             } )
      }
    }, (err) => {             console.clear();
     // Swal2.close();
      Swal2.fire(
        'Error al acceder',
        'Revise su información',
        'error'
     );
  }
   );
  }

  recuperarcont() {
    this.recuperacont = !this.recuperacont;
  }

  enviacrecuperacion() {
    const email = (document.getElementById('emailrec') as HTMLInputElement).value;
    this.usuarioservice.getResetPaswword(email).subscribe( resp => { this.recuperacont = !this.recuperacont;  console.log(resp);
                                                                      Swal2.fire(
        'El correo de recuperacion de contraseña fue enviado a:',
        email,
        'success'
     );
    }, (err) => {
      console.log(err);
      Swal2.fire(
           'Error',
           err.error.errors[0],
           'error'
        );
     } );
  }

  clicPass(){
    var x = (document.getElementById("password") as HTMLInputElement);
    if (x.type === "password") {
      x.type = "text";
    } 
  }

  liftPass(){
    var x = (document.getElementById("password") as HTMLInputElement);
    if (x.type === "text") {
      x.type = "password";
    } 
  }

}
