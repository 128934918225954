import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-creadocumento',
  templateUrl: './creadocumento.component.html',
  styles: []
})
export class CreaDocumentoComponent implements OnInit {

  forma: FormGroup;
  ido: string;
  service: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private documentosService: DocumentosService,
    public router: Router
  ) { }


  ngOnInit() {
    this.forma = new FormGroup({
      Tdocumento: new FormControl( null , Validators.required ),
      Nombre: new FormControl( null , Validators.required ),
      Descripcion: new FormControl( null , Validators.required ),
      Validacion: new FormControl( null ),
      Sexterno: new FormControl( null )
    } );
    this.documentosService.getExtServices().subscribe( resp => {this.service = resp; console.log(this.service) });
  }


  CreaDocumento() {

    const Tdocumento: any = (document.getElementById('Tdocumento')as HTMLInputElement).value;
    const Sexterno: any = (document.getElementById('Sexterno')as HTMLInputElement).value;
    const Nombre: any = (document.getElementById('Nombre')as HTMLInputElement).value;
    const Descripcion: any = (document.getElementById('Descripcion')as HTMLInputElement).value;
    const Validacion: any = (document.getElementById('Validacion')as HTMLInputElement).value;

    let params = {
      document_type: Tdocumento,
      name: Nombre,
      description: Descripcion,
      validation: Validacion,
      ext_service_id: Sexterno,
      token: '',
      secret_key: ''
    }

    if ( Sexterno === "" ) {
        delete params.ext_service_id;
    }

    this.documentosService.crearDocumento( params ).subscribe( () => {
    Swal.fire({
      title: 'Creacion de documento exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/documento']);
      }

    } );

  }, (err) => {             console.clear();
     console.log(err);
                            Swal.fire(
                              'Error al crear el documento',
                              'Error',
                              'error'
                           );
                        } );

  } 


}
