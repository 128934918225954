import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class SignatoryesService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router,
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

  getCustomerCredits( ) {

    const url = `${environment.URL_SERVICIOS}/customer_credits?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArreglo(resp);
    }));

    }

    crearArreglo( contribuObj: any) {
        const rr: any[] = [];
        const resul: any[] = [];
        if ( contribuObj === null ) { return []; }
        Object.keys ( contribuObj ).forEach( key => {
          const rol: any = contribuObj[key];
          rr.push( rol );
        });
        // tslint:disable-next-line: forin
        for ( const prop in rr[0] ) {
          resul.push( rr[0][prop].attributes );
        }
        return resul;
      }

  getSignatoryesXidcuatcredit(customerCredit: string) {
    const url = `${environment.URL_SERVICIOS}/customer_credits_signatory/customer_credit_id?id=${customerCredit}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get( url ).pipe(
      map( (resp: any) => {return (resp);
      }));
  }

  patchSignatory(obj) {
    let status = 'EA'

    const url = `${environment.URL_SERVICIOS}/customer_credits_signatories/${obj.id}?customer_credits_signatories[customer_credit_id]=${obj.customer_credit_id}&customer_credits_signatories[user_id]=${obj.user_id}&customer_credits_signatories[status]=${status}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
    return this.http.patch( url, null ).pipe(
      map( (resp: any) => { return resp;
      } ));
    } 
  
    async getusuario( idu ) {
      const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
      try {
        let rr = this.http.get(url).toPromise()
        return (rr)
      } catch (error) {
        return 'error'
      }
      
    }

    async getRol( idr ) {
      const url = `${environment.URL_SERVICIOS}/roles/${idr}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
      let rr = this.http.get(url).toPromise()
      try {
        let rr = this.http.get(url).toPromise()
        return (rr)
      } catch (error) {
        return 'error'
      }
      
    }

    patchCustomerCreditPR(idc: string) {
      const url = `${environment.URL_SERVICIOS}/customer_credits/${idc}?customer_credit[status]=PR&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
      return this.http.patch(url, null).pipe(
        map( (resp: any) => {
          return (resp);
        } )
      );
    }

}
