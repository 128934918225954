import { Component, OnInit } from '@angular/core';
import { CreditosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-calificacioncreditos',
  templateUrl: './calificacioncreditos.component.html',
  styleUrls: ['./calificacioncreditos.component.css']
})
export class CalificacioncreditosComponent implements OnInit {

  cols: any[];
  calificacioncreditos: any[];
  constructor(private creditosservice: CreditosService) { }

  ngOnInit(): void {
    swal2.showLoading();
    this.creditosservice.getCalificacionCreditos().subscribe( resp => { this.calificacioncreditos = resp; console.log(this.calificacioncreditos) } );

this.cols = [
  
{ field: 'key', header: 'Key' },
{ field: 'description', header: 'Descripcion' },
{ field: 'value', header: 'Valor' },
{ field: 'cr_type', header: 'Tipo calificacion' },
{ field: 'herramientas', header: 'Herramientas' }
  
];
swal2.close();
  }

  borraCalificacionCredito( id: string ) {
    swal2.showLoading();
    swal2.fire({
    title: 'Desea Eliminar la calificacion',
    text: 'Seleccionada',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
    }). then ( resp => {
    if ( resp.value) {
    
    this.creditosservice.borrarCalificacionCredito( id ).subscribe( () => {
    swal2.close();
    swal2.fire({
     title: 'La calificacion',
     text: 'fue eliminada con exito',
     icon: 'success',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
    
     if ( res.value ) {
       this.ngOnInit();
     }
    
    } );
    
    }, (err) => {
    swal2.close();
    console.log(err);
    console.clear();
    swal2.fire({
     title: 'Ocurrio un error',
     text: '',
     icon: 'error',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
     if ( res.value ) {
       this.ngOnInit();
     }
    } );
    } );
    
    }
    });
    
    }   

}
