import { Component, OnInit } from '@angular/core';
import { DocumentosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})
export class DocumentoComponent implements OnInit {

  cols: any[];
  documentos: any[];
  loading: boolean;
  constructor( private documentosService: DocumentosService ) { }

  ngOnInit(): void {
    swal2.showLoading();
    this.documentosService.getDocumentos().subscribe( resp => { this.documentos = resp;
      for (const prop in this.documentos) {
        if ( this.documentos[prop].ext_service_id !== null ) {
          if(this.documentos[prop].ext_service_id.length > 4) {
            this.documentosService.getExtService( this.documentos[prop].ext_service_id ).subscribe( resp => this.documentos[prop].ext_service_id = resp[0].supplier )
          }
          
        }
      }
      swal2.close(); } );


this.cols = [
{ field: 'document_type', header: 'Tipo documento' },
{ field: 'ext_service_id', header: 'Servicio externo' },
{ field: 'name', header: 'Nombre' },
{ field: 'description', header: 'Descripcion' },
{ field: 'validation', header: 'Validacion' },
{ field: 'herramientas', header: 'Herramientas' }

];

}

loadCustomers() {  
  this.loading = true;

  setTimeout(() => {
      this.loading = false;
  }, 1000);
}

 borraDocumento( id: string ) {
swal2.showLoading();
swal2.fire({
title: 'Desea Eliminar el documento',
text: 'Seleccionado',
icon: 'question',
showConfirmButton: true,
showCancelButton: true,
allowOutsideClick: false
}). then ( resp => {
if ( resp.value) {

this.documentosService.borrarDocumento( id ).subscribe( () => {
swal2.close();
swal2.fire({
 title: 'El documento',
 text: 'fue eliminado con exito',
 icon: 'success',
 showConfirmButton: true,
 showCancelButton: false,
 allowOutsideClick: false
}). then ( res => {

 if ( res.value ) {
   this.ngOnInit();
 }

} );

}, (err) => {
swal2.close();
console.log(err);
console.clear();
swal2.fire({
 title: 'Ocurrio un error',
 text: '',
 icon: 'error',
 showConfirmButton: true,
 showCancelButton: false,
 allowOutsideClick: false
}). then ( res => {
 if ( res.value ) {
   this.ngOnInit();
 }
} );
} );

}
});

}

}
