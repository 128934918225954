import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-empleados-yucatan-gob',
  templateUrl: './empleados-yucatan-gob.component.html',
  styles: [
  ]
})
export class EmpleadosYucatanGobComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  edusuario = true;
  agregarusuario = false;
  items = [];
  cols = [

    { field: 'first_name', header: 'Nombre' },
    { field: 'last_name', header: 'Apellido paterno' },
    { field: 'second_last_name', header: 'Apellido materno' },
    { field: 'credit', header: 'Simular crédito' },
];
  currentPage = 1;
  itemsPerPage = 7;
  searchTerm = '';
  filaSeleccionada: number;
  showac = false;
  actualizausuarioEmitter = new EventEmitter<Object>();

  constructor(private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    /* this.edusuario = true;
    this.agregarusuario = false; */
    this.usuarioService.getGovCustomers().subscribe(resp => {
      this.items = resp; 
      this.items.sort((a, b) => this.compare(a, b, 'created_at', 'desc'));
      swal2.close();
    }, (err) => {
      swal2.fire({title: 'Ocurrio un error al cargar la informacion', allowOutsideClick: false })
    });
  }

  ngOnDestroy(): void { 
    this.actualizausuarioEmitter.unsubscribe();
  } 

  private compare(a: any, b: any, column: string, direction: 'asc' | 'desc'): number {
    if (!column) return 0;
  
    const valueA = a[column];
    const valueB = b[column];
  
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
  
    return direction === 'asc' ? comparison : -comparison;
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get filteredData(): any[] {
    return this.items.filter(item =>
      item.first_name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.last_name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.second_last_name.toLowerCase().includes(this.searchTerm.toLowerCase()) 
    );
  }

  resetIndex() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  desplegarEditarUsuario() {
    this.edusuario = !this.edusuario;
    this.agregarusuario = false;
  }
  
  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  editarUsuario(contid: any, nomcont: any) {
    this.showac = true;
    this.edusuario = false;
    this.agregarusuario = false;
    setTimeout(() => {
      this.actualizausuarioEmitter.emit({contid: contid, nomcont: nomcont});
    }, 100);   
  }

  agregar(id?) {
    this.agregarusuario = true;
    if(id) {
      console.log('id', id);
      /* setTimeout(() => {
        this.actualizausuarioEmitter.emit({contid: contid, nomcont: nomcont});
      }, 100); */
    }
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  cierraregusr(event) {
    this.agregarusuario = false;
    this.ngOnInit();
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }
}
