import { Component, OnInit } from '@angular/core';
import noUiSlider from "nouislider"
@Component({
    selector: 'app-oportunidad',
    templateUrl: './oportunidad.component.html'
})

export class OportunidadComponent implements OnInit {
  focus: any;
  focus1: any;
  info1: any = [];
  val1: number;
  constructor() { }
  ngOnInit() {
    this.val1 = 0;
    this.info1 = [{ nombre_empresa: 'Servicios comerciales Amazon sa de cv', 
                    descripcion_empresa: 'Empresa de Ventas por internet y servicios de tecnologia',
                    a_fondear: '1,200,150',
                    moneda: 'MXN',
                    porcentaje: '69',
                    dias_restantes: '30',
                    tasa: '16',
                    plazo: '36',
                    garantia: 'SI',
                    inversion_minima: '500',
                    calificacion: 'A',
                    rendimiento: 'Mensual',
                    imagen: 'https://static2.abc.es/media/tecnologia/2020/08/31/amazon-k4I--1200x630@abc.jpg',
                    id: 1,
                    fundacion: '5 de julio de 1994',
                    ubicacion: 'Juan Salvador Agraz 73, Lomas de Santa Fe, Contadero, Cuajimalpa de Morelos, 05348 Ciudad de México, CDMX',
                    actividad: 'Ventas por internet',
                    tamano: 'Grande',
                    web: 'http://www.amazon.com'
  }];
  }

  calc() {
    console.log(this.val1);
  }

}
