import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { CreditosService } from '../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from '../../services/usuario/usuario.service';
import swal2 from 'sweetalert2';
import { RolesService } from '../../services/roles/roles.service';
import { ListasService } from '../../services/listas/listas.service';
import { info } from 'console';
@Component({
  selector: 'app-solicitudcredito',
  templateUrl: './solicitudcredito.component.html',
  styleUrls: ['./maincliente.component.css']
})
export class SolicitudcreditoComponent implements OnInit {
  paymentperiods: any[] = [];
  condiciones: any[] = [];
  monto: string;
  muestraCalculo = false;
  intereses: string;
  iva: string;
  montototal: string;
  mt: number;
  customer;
  uid;
  payments = [];
  customer_credits = [];
  solicitarCredito;
  comision = 0;
  seguro: string;
  showModalTablaAmortizacion = false;
  showModalNip = true;
  showModalNip2 = false;
  nipValidado = false;
  bandera = true;
  rates = [];
  ivaRates = [];
  fijo = false;
  pago = '';
  cols = [

    { field: 'credit_folio', header: 'Folio', moneda: false },
    { field: 'created_at', header: 'Fecha solicitud', moneda: false },
    { field: 'total_requested', header: 'Pedido Total', moneda: true },
    { field: 'interests', header: 'Intereses', moneda: true },
    { field: 'total_debt', header: 'Adeudo Total', moneda: false },
    { field: 'status', header: 'Estado', moneda: false },
  ];
  lists = [];
  credit_status = [];
  solicitud_admin = false;
  anualidad = false;
  anuality_dates = [
    {value: 'ENERO', key: '1'},
    {value: 'FEBRERO', key: '2'},
    {value: 'MARZO', key: '3'},
    {value: 'ABRIL', key: '4'},
    {value: 'MAYO', key: '5'},
    {value: 'JUNIO', key: '6'},
    {value: 'JULIO', key: '7'},
    {value: 'AGOSTO', key: '8'},
    {value: 'SEPTIEMBRE', key: '9'},
    {value: 'OCTUBRE', key: '10'},
    {value: 'NOVIEMBRE', key: '11'},
    {value: 'DICIEMBRE', key: '12'},
  ];
  customer_credit_id = '';
  customer_credit = {
    credit_info: {
      total_requested: ''
    },
    credit_payments: {}
  };
  signatories = {};
  customer_credits_rejected = [];
  customer_credits_eval = [];
  period_anuality = 'true';
  monto_anualidad: any;
  anuality_date = 'na';
  exclude_limit = false;
  exclude_senority = false;
  indicadorPosicion: string;
  has_guarantee_object = false;
  guarantee_object = '';
  guarantee_object_address = '';
  guarantee_email = '';


  constructor( private creditosService: CreditosService,
                public router: Router,
                private route: ActivatedRoute,
                private usuarioService: UsuarioService,
                private rolesService: RolesService,
                private listasService: ListasService  ) { }

  ngOnInit(){
    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
      this.credit_status = this.lists.filter(p => p.domain === 'CREDIT_STATUS');
    })
    this.creditosService.getperiodosPagos().toPromise().then ( resp => this.paymentperiods = resp );
    this.creditosService.ext_rates().subscribe(resp => {
      resp['data'].forEach(rate => {
        if(rate.attributes.rate_type === 'TA'){
          this.rates.push(rate.attributes);
        }else if(rate.attributes.rate_type === 'IVA'){
          this.ivaRates.push(rate.attributes);
        }
      });
    })

    if(this.route.snapshot.paramMap.get('customer_credit_id')){
      this.solicitud_admin = true;
      this.customer_credit_id = this.route.snapshot.paramMap.get('customer_credit_id');
      this.creditosService.getCredit(this.customer_credit_id).subscribe(resp => {
        this.customer_credit = {credit_info: resp['data']['attributes'], credit_payments: resp['data']['relations']['current_payments']}
        this.uid = this.customer_credit['credit_info']['user_id'];
        this.getCustomer();
        this.indicadorPosicion = 'aprobar'
      })
    }else if(this.route.snapshot.paramMap.get('user_id')){
      this.uid = this.route.snapshot.paramMap.get('user_id');
      this.solicitud_admin = true;
      this.getCustomer();
      this.indicadorPosicion = 'simulador'
    }else{
      // Funcionamiento normal, cliente haciendo solicitud
      this.uid = localStorage.getItem('uid');
      this.getCustomer();
      this.solicitud_admin = false;
      this.indicadorPosicion = 'na'
    }
    
  }

  // REVISADAS

  getCustomer(){
    this.usuarioService.getCustomer(this.uid).subscribe(resp => {

      console.log('customer', resp)
      this.customer = resp['data'].relations.customers[0].attributes;
      this.creditosService.getCondiciones().subscribe( resp => {
        this.condiciones = resp.filter(c => c.term_type === this.customer.salary_period && c.extra1 != 'CUSTOM')
        this.pago = 'plazo';
      } );
      this.creditosService.getCredits().subscribe(resp2 => {
        let creditos_totales = [];
        Object.keys(resp2['data']).forEach(key => {
          let credit = resp2['data'][key].attributes;
          try{
            let status = this.credit_status.find(s => s.key == credit.status);
            credit.status = status.value;
          }catch{}
          creditos_totales.push(credit);
        })
        this.customer_credits = creditos_totales.filter(credito => credito.customer_id === this.customer.id && credito.status != 'REVISADO' && credito.status != 'LIQUIDADO' && credito.status != 'RECHAZADO');
        this.customer_credits_rejected = creditos_totales.filter(credito => credito.customer_id === this.customer.id && credito.status == 'RECHAZADO');
        if(this.customer_credits_rejected.length > 0){
          swal2.fire('Atención', 'El crédito solicitado fue rechazado anteriormente, contacte al administrador para más detalles', 'info');
        }
        if(this.customer_credits.length > 0){
          this.solicitarCredito = false;
        }else{
          this.solicitarCredito = true;
          this.customer_credits_eval = creditos_totales.filter(credito => credito.customer_id === this.customer.id)
          if(this.customer_credits_eval.length > 0){
            this.resetNip();
          }
        }

      })
    });
  }

  showModalNip2now(){
    (document.getElementById('btnSolicitar') as HTMLButtonElement).disabled = true;
    this.showModalNip2 = true;
  }

  validarNip(){
    // VALIDA EL NIP POR PRIMERA VEZ Y MUESTRA 
    var nip = (document.getElementById('nip') as HTMLInputElement).value;
    // ENVIA EL CUSTOMER ID
    this.usuarioService.getNipStatus(nip,this.customer).subscribe( resp => {
      if ( resp.status !== true ) {
        swal2.fire(
        'El NIP es incorrecto',
        'Intente de nuevo',
        'error'
        
     ).then(okay => {
      // if (okay) {
        swal2.close();
       this.focusNip();
    //  }
   });
      this.showModalNip = true;
      this.nipValidado = false;
      (document.getElementById('nip') as HTMLInputElement).value = ''

    } else {
      swal2.fire('Éxito', 'NIP validado correctamente', 'success');
          this.showModalNip = false;
          this.nipValidado = true;
          }
      }
      );  
  }

  validarNip2(){
    (document.getElementById('btnValidar') as HTMLButtonElement).disabled = true;
    // VALIDA EL NIP POR SEGUNDA VEZ Y CREAR EL CREDITO COMO SO
    var nip = (document.getElementById('nip2') as HTMLInputElement).value;
    this.usuarioService.getNipStatus(nip,{id: this.customer.id}).subscribe( resp => {
      if ( resp.status !== true ) {
        swal2.fire(
        'El NIP es incorrecto',
        'Intente de nuevo',
        'error'
        );
        this.showModalNip2 = true;
        this.nipValidado = false;
        (document.getElementById('nip2') as HTMLInputElement).value = ''
      } else {
        swal2.fire('Éxito', 'NIP validado correctamente', 'success');
        this.showModalNip2 = false;
        this.nipValidado = true;
        // console.log("AQUI VA A MANDAR A GUARDAR EL CREDITO COMO SO")

        this.calcular('SO')

      }
    });
  }

  resetNip(){
    // SERVICIO PARA GENERAR NUEVO NIP
    this.usuarioService.resetNip(this.customer).subscribe( resp => {
      if ( resp.status !== true ) {
        swal2.fire(
        'Ha ocurrido un error',
        'Vuelva a intentarlo',
        'error'
     );
    } else {
      swal2.fire('Se le ha enviado un nuevo NIP', 'Revise su correo', 'success');
          }
      }
      );  
  }

  focusNip() {
    var input = (document.getElementById('nip')as HTMLInputElement);
    input.focus();
  }

  showTablaAmortizacion() {
    this.showModalTablaAmortizacion = true; // Show-Hide Modal Check
  }

  hideModalTablaAmortizacion() {
    this.showModalTablaAmortizacion = false;
  }  

  showModalTablaAmortizacionActivos(id) {
    this.payments = [];
    this.creditosService.customerCreditPayments(id).subscribe(pays => {
      pays['data'].forEach(p => {
        this.payments.push(p['attributes']);
      });
    });
    
    this.showModalTablaAmortizacion = true; // Show-Hide Modal Check
  }

  calcular(status) {
    // swal2.showLoading();
    Swal.fire({
      title: 'Procesando petición',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
          Swal.showLoading()
      },
    });

    this.monto = (document.getElementById('monto')as HTMLInputElement).value;
    if (this.monto === '' || parseInt(this.monto) < 0) {
      Swal.fire('Valor inválido', 'El valor debe contener un valor y ser mayor a 0', 'info');
    } else {      
      let date = new Date();
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();  
      let start_date;

      if(month < 10){
        start_date =  `${year}-0${month}-${day}`;
      }else{
        start_date = `${year}-${month}-${day}`;
      }

      const params = {};

      let customer_credit = {
        total_requested: this.monto,
        status: status,
        user_id: this.uid,
        start_date: start_date,
        currency: 'PESOS',
        iva_percent: (document.getElementById('fronterizo') as HTMLInputElement).value,
        has_guarantee_object: this.has_guarantee_object,
        guarantee_object: this.guarantee_object,
        guarantee_object_address: this.guarantee_object_address,
        guarantee_email: this.guarantee_email,
      }

      if(this.anualidad){
        let monto_anualidad = (document.getElementById('monto_anualidad') as HTMLInputElement).value;
        if(monto_anualidad != '' && parseInt(monto_anualidad) > 0){
          params['anuality'] = this.monto_anualidad;
          params['anuality_date'] = parseInt(this.anuality_date);
          params['period_anuality'] = this.period_anuality;
        }else{
          Swal.fire('Valor de anualidad inválido', 'El valor debe contener un valor y ser mayor a 0', 'info');
        }
      }
      customer_credit['customer_id'] = this.customer.id;



      if(this.pago == ''){
        swal2.fire('Datos incompletos', 'Debes seleccionar un método de pago', 'info');
        return;
      }else if(this.pago == 'plazo'){
        customer_credit['term_id'] = (document.getElementById('Condicione')as HTMLInputElement).value;
      }else if(this.pago == 'fijo'){
        params['payment_amount'] = (document.getElementById('pagoFijo')as HTMLInputElement).value;
      }
      params['customer_credit'] = customer_credit;

      params['old_customer_credit'] = this.customer_credit_id;
      
      params['exclude_limit'] = this.exclude_limit;
      params['exclude_senority'] = this.exclude_senority;

      console.log(params);

      this.creditosService.customerCredit(params).subscribe(resp => {
        swal2.close();
        this.muestraCalculo = true;
        this.payments = [];
        resp['data'].relations.current_payments.forEach(payment => {
          this.payments.push(payment.attributes);
        });
        this.monto = resp['data'].attributes.total_requested;
        this.intereses = resp['data'].attributes.interests;
        this.iva = resp['data'].attributes.iva;
        this.comision = resp['data'].attributes.commission1;
        this.seguro = resp['data'].attributes.insurance1;
        this.montototal = resp['data'].attributes.total_debt;
        if(status === 'SO'){
          swal2.fire('Éxito', 'Estamos revisando tu solicitud, nos contactaremos contigo pronto', 'success');
          this.ngOnInit();
          window.location.reload();
        }
        
        else if(status === 'PA'){
          if(this.customer_credit_id != ''){
            try{
              this.creditosService.updateCredit(this.customer_credit_id, {status: 'RE'}).subscribe(() => {
                console.log(resp);
                this.creditosService.getSignatoriesByCreditId(this.customer_credit_id).subscribe(st => {
                  this.signatories = st['signatories'];
                  Object.keys(this.signatories).forEach(signatory => {
                    this.creditosService.updateSignatory(this.signatories[signatory].id, resp['data'].id).subscribe();
                  })
                }, err => {
                  console.log('Algo salió mal', err.error.errors[0])
                })
              },error => {
                console.log(error);
                try{
                  swal2.fire('Atención', error.error.error, 'info');
                }catch{
                  swal2.fire('Atención', 'Algo salió mal', 'info');
                }
              })
              // swal2.fire('Atención', err.error.error, 'info');
            }catch{
              swal2.fire('Atención', 'Algo salió mal', 'info');
            }
          }
            
          swal2.fire('Éxito', 'Crédito solicitado con éxito', 'success');
          this.router.navigate(['/panelcontrol/aprobarCreditos/']);
        }

      }, err => {
        console.log('err', err);
        if(err.error.errors){
          swal2.fire('Atención', err.error.errors[0], 'info').then(() => {
            swal2.close();
            this.muestraCalculo = false;
            this.ngOnInit();
            })
        }else{
          swal2.fire('Algo salió mal', 'Contacte al Administrador', 'info');
          this.muestraCalculo = false;
        }
      })
    }
  
  }
  // ---------------------------


}
