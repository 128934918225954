import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import * as fs from 'file-saver';
import { Angular2Txt } from 'angular2-txt/Angular2-txt';
import { ReportesService } from 'src/app/services/reportes/reportes.service';

@Component({
  selector: 'app-reporteconsultasburo',
  templateUrl: './reporteconsultasburo.component.html',
  styles: [
  ]
})
export class ReporteConsultasBuroComponent implements OnInit {

  constructor( public _reportesservice: ReportesService,
    public http: HttpClient) { }

token = localStorage.getItem('token');
//doc = new jsPDF();
facturas: any[] = [];
usuario: string;
cols: any[];
colspdf: any[];
selectedFac: any[];
router: Router;
fileName = 'ListaDeFacturas.xlsx';
selectedColumnsp: any[];
selectedColumnspdf: any[];
exportColumns: any[];

_selectedColumns: any[];

ngOnInit() {

  this.cols = [

    { field:  'no', header: 'No'},
    { field:  'noreferenciaoperador', header: 'No. de transaccion(Referencia del operador)'},
    { field:  'fechas', header: 'Fecha'},
    { field:  'nombre', header: 'Nombre(s)'},
    { field:  'paterno', header: 'Paterno'},
    { field:  'materno', header: 'Materno'},
    { field:  'rfc', header: 'RFC'},
    { field:  'calleynumero', header: 'Calle-No.'},
    { field:  'colonia', header: 'Colonia'},
    { field:  'ciudad', header: 'Ciudad'},
    { field:  'edo', header: 'Edo'},
    { field:  'ingresonip', header: 'Ingreso nuevamente de NIP'},
    { field:  'respuestaautorizacion', header: 'Respuesta de leyenda de autorizacion'},
    { field:  'cuenta', header: 'Cuenta'},
    { field:  'foliobc', header: 'No. folio de consulta BC'}

  ];

  this._selectedColumns = this.cols;
  this.colspdf = [

    //  { field:  'id_factura', header: 'ID'},
    { field:  'no', header: 'No'},
    { field:  'noreferenciaoperador', header: 'No. de transaccion(Referencia del operador)'},
    { field:  'fechas', header: 'Fecha'},
    { field:  'nombre', header: 'Nombre(s)'},
    { field:  'paterno', header: 'Paterno'},
    { field:  'materno', header: 'Materno'},
    { field:  'rfc', header: 'RFC'},
    { field:  'calleynumero', header: 'Calle-No.'},
    { field:  'colonia', header: 'Colonia'},
    { field:  'ciudad', header: 'Ciudad'},
    { field:  'edo', header: 'Edo'},
    { field:  'ingresonip', header: 'Ingreso nuevamente de NIP'},
    { field:  'respuestaautorizacion', header: 'Respuesta de leyenda de autorizacion'},
    { field:  'cuenta', header: 'Cuenta'},
    { field:  'foliobc', header: 'No. folio de consulta BC'}
  ];
  this.selectedColumnsp = this.cols;
  this.exportColumns = this.colspdf.map(col => ({title: col.header, dataKey: col.field}));

}

@Input() get selectedColumns(): any[] {
  return this._selectedColumns;
}

set selectedColumns(val: any[]) {
  // restore original order
  this._selectedColumns = this.cols.filter(col => val.includes(col));
}

generarReporte() {

  swal2.fire({
    title: 'Cargando',
    allowOutsideClick: false
  });
  swal2.showLoading();
  const fechainicial = new Date((document.getElementById('fechainicial')as HTMLInputElement).value);
  const fechafinal = new Date((document.getElementById('fechafinal')as HTMLInputElement).value);
  fechainicial.setMinutes( fechainicial.getMinutes() + fechainicial.getTimezoneOffset() );
  let month1 = '' + (fechainicial.getMonth() + 1);
  let day1 = '' + fechainicial.getDate();
  const year1 = fechainicial.getFullYear();

  if (month1.length < 2) {
    month1 = '0' + month1;
  }
  if (day1.length < 2) {
    day1 = '0' + day1;
  }

  fechafinal.setMinutes( fechafinal.getMinutes() + fechafinal.getTimezoneOffset() );
  let month2 = '' + (fechafinal.getMonth() + 1);
  let day2 = '' + fechafinal.getDate();
  const year2 = fechafinal.getFullYear();

  if (month2.length < 2) {
    month2 = '0' + month2;
  }
  if (day2.length < 2) {
    day2 = '0' + day2;
  }

  const fecha1 = [year1, month1, day1].join('-');
  const fecha2 = [year2, month2, day2].join('-');
  this._reportesservice.getReporteConsultasBuro(fecha1,fecha2).subscribe(resp => {
    this.facturas = resp; 
    console.log(this.facturas)
    swal2.close();
    if ( this.facturas.length === 0 ) {

      swal2.fire(
        'No se encontraron datos con las fechas seleccionadas:',
        
        'error'
        );
    } 
  }, (err) => {
    swal2.close();
    console.log(err);
    swal2.fire(
      'Error al Consultar los Datos',
      '',
      'error'
    );
    this.ngOnInit();
  } );


}


exportexcel() {
  this.fileName = this.facturas[0].buro_consults_report + '.xlsx';
  const Excel = require('exceljs');
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet('base');
  let header=['No','No. de transaccion(Referencia del operador)','Fecha','Nombre(s)','Paterno','Materno','RFC','Calle-No','Colonia','Ciudad','Edo','Ingreso nuevamente de NIP','Respuesta de leyenda de autorizacion','Cuenta','No. folio de consulta BC',
];

  worksheet.addRow(header);
  for (const prop in this.facturas) {
    worksheet.addRow([
        this.facturas[prop].no,
        this.facturas[prop].noreferenciaoperador,
        this.facturas[prop].fechas,
        this.facturas[prop].nombre,
        this.facturas[prop].paterno,
        this.facturas[prop].materno,
        this.facturas[prop].rfc,
        this.facturas[prop].calleynumero,
        this.facturas[prop].colonia,
        this.facturas[prop].ciudad,
        this.facturas[prop].edo,
        this.facturas[prop].ingresonip,
        this.facturas[prop].respuestaautorizacion,
        this.facturas[prop].cuenta,
        this.facturas[prop].foliobc

    ]);
} 
workbook.xlsx.writeBuffer().then((data) => {
  let blob = new Blob([data], { type: '.xlsx' });
  const file = blob;
  fs.saveAs(blob, this.fileName);
});
}


exporttxt() {
  var options = { 
    fieldSeparator: ' ',
    quoteStrings: ''
  };
  let datos = [];
  this.fileName = this.facturas[0].buro_consults_report;
  for (const prop in this.facturas) {
    if (this.facturas[prop].colonia === null) {
      this.facturas[prop].colonia = ''
    }
    // this.facturas[prop].importe = this.facturas[prop].importe.replaceAll(',','')
    datos.push({
      no: this.facturas[prop].no,
      nr: this.facturas[prop].noreferenciaoperador,
      fe: this.facturas[prop].fechas,
      nm: this.facturas[prop].nombre,
      pa: this.facturas[prop].paterno,
      ma: this.facturas[prop].materno,
      rf: this.facturas[prop].rfc,
      cl: this.facturas[prop].calleynumero,
      co: this.facturas[prop].colonia,
      ci: this.facturas[prop].ciudad,
      ed: this.facturas[prop].edo,
      in: this.facturas[prop].ingresonip,
      re: this.facturas[prop].respuestaautorizacion,
      cu: this.facturas[prop].cuenta,
      fo: this.facturas[prop].foliobc
    })
  }
  new Angular2Txt(datos, this.fileName, options);

}

}
